import { age } from '@marmalade/fdi/lib/constants';
import { getQuoteInfo, hasQuoted } from '@marmalade/fdi/lib/quick-quote/selectors';
import { bool, func, string } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { VEHICLE_CAVEATS } from '../../../../constants';
import urls from '../../../urls';
import Page from '../components/quote/get-a-quote';
import Header from '../images/nyd-header.jpg';
import Documents from './documents';
import Form from './form';
import reset from './reset';
const results = urls.fdi + '/your-quote';

FDIGetAQuote.propTypes = {
    error: string.isRequired,
    reset: func.isRequired,
    quoted: bool.isRequired,
};

export const USPList = [
    "No risk to the parent's No Claims Bonus",
    'Alternative to adding a young person as a named driver, if they’re driving less than 50% of the time',
    `For full and provisional licence holders aged ${age.min}-${age.max}`,
    VEHICLE_CAVEATS,
    'Cover available in most UK postcodes including Northern Ireland',
];

export const USPListAnnual = [
    'Young driver earns own no claims bonus',
    `For Full and Provisional Licence holders aged ${age.min}-${age.max}`,
    'No additional premium when pass driving test',
    'No risk to parent’s no claims bonus',
    'Cover for up to 10,000 miles (policyholder must have 50% or less use of car)',
    'Telematics tag for young driver behaviour only',
    'No curfews or additional premiums',
];

function FDIGetAQuote({ error, quoted, reset }) {
    return (
        <Page
            reset={reset}
            quoted={quoted}
            results={results}
            error={error}
            title="Named Young Driver Insurance"
            lead={
                <Fragment>
                    Black box insurance when insuring a young driver on a family car. You can earn 10% of the premium or
                    £100, whichever is lower, per new policy sold.
                </Fragment>
            }
            image={Header}
            list={USPList}
            renewalMessage="Remember you can earn 10% of the premium or £100 (whichever is lower) with Named Driver Insurance."
            product="fdi"
            documents={<Documents />}
            form={<Form />}
        />
    );
}

export default connect(
    state => {
        let error = '';
        const quote = getQuoteInfo(state);
        if (quote.requestError) {
            error = 'Sorry, there was an error fetching quote prices.';
        } else if (quote.quoteError) {
            error = 'Sorry, we are not able to provide a quote for the requested details.';
        }
        return { quoted: hasQuoted(state), error };
    },
    { reset }
)(FDIGetAQuote);
