import React from 'react';
import Grid from 'marketing/components/grid';
import { node, arrayOf, bool } from 'prop-types';

ButtonGrid.propTypes = {
    children: arrayOf(node),
    vertical: bool,
};

function ButtonGrid({ children, vertical = false }) {
    return (
        <Grid rows={vertical ? 1 : children.length} marginLeft=".2rem" marginRight="0">
            {wrap => children.map((child, i) => wrap(child, `${i}`))}
        </Grid>
    );
}

export default ButtonGrid;
