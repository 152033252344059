import React from 'react';
import { string, object } from 'prop-types';
import { withTheme, Span } from 'glamorous';
import Icon from '@marmalade/icon';

ContactListItem.propTypes = {
    link: string,
    id: string,
    icon: string.isRequired,
    text: string.isRequired,
    theme: object.isRequired,
};

ContactListItem.defaultProps = {
    link: undefined,
    id: undefined,
};

function ContactListItem({ link, icon, text, theme, id }) {
    return (
        <a href={link} id={id}>
            <Icon
                type={icon}
                css={{
                    fontSize: '26px',
                    marginRight: theme.space_sm,
                    verticalAlign: 'middle',
                }}
            />
            <Span verticalAlign="middle">{text}</Span>
        </a>
    );
}

export default withTheme(ContactListItem);
