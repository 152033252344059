import Button, { ButtonGroup } from '@marmalade/button';
import domain from '@marmalade/domain';
import ldiBrokerInsert from 'media/inserts/ldi-insert.pdf';
import React from 'react';
import MultiDayPrices, { priceProp } from '../../components/results/multi-day-prices';
import Caveats from '../../learner/caveats';
import { USPList as ldiList } from '../../learner/get-a-quote';
import ResultsPanel from './results-panel';

LDIResults.propTypes = {
    prices: priceProp.isRequired,
};

function LDIResults({ prices }) {
    return (
        <ResultsPanel
            title="Learner Driver Insurance"
            paragraph="Short term comprehensive cover for learners to drive a family member's or friend's car."
            buttons={
                <ButtonGroup>
                    <Button
                        Element="a"
                        href={`${domain('broker.provisionalmarmalade')}/broker-login`}
                        prominence="primary"
                        target="_blank"
                    >
                        Proceed to full quote
                    </Button>
                </ButtonGroup>
            }
            list={ldiList}
            brokerInsert={ldiBrokerInsert}
            caveat={<Caveats />}
        >
            <MultiDayPrices prices={prices} />
        </ResultsPanel>
    );
}

export default LDIResults;
