import React from 'react';
import { H3 } from '@marmalade/bling/lib/headers/default';
import Button from '@marmalade/button';
import domain from '@marmalade/domain';
import Text from '../../../../components/grey-paragraph';
import PanelWithIcon from '../panel-with-icon';

function LDIAccountPanel() {
    return (
        <PanelWithIcon icon="learner">
            <H3>Learner Driver Portal</H3>
            <Text>Login to the portal to process full applications and view renewals.</Text>
            <Button
                Element="a"
                href={`${domain('broker.provisionalmarmalade')}/broker-login`}
                prominence="secondary"
                target="_blank"
            >
                Process Policies
            </Button>
        </PanelWithIcon>
    );
}

export default LDIAccountPanel;
