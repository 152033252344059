import { Select } from '@marmalade/accesibility';
import { H3 } from '@marmalade/bling/lib/headers/default';
import glamorous, { withTheme } from 'glamorous';
import { arrayOf, bool, node, object, oneOfType, string } from 'prop-types';
import React, { Component, Fragment } from 'react';
import P from '../../../../components/grey-paragraph';
import isDesktop from '../../../../is-desktop';
import { DocumentList } from '../../components/documents';
import FeatureList from '../../components/features-tick-list';
import { FeaturePanel, PricePanel } from './panels';

const PanelHeader = glamorous(H3)({ marginBottom: 0 });

class ResultsPanel extends Component {
    static propTypes = {
        title: string.isRequired,
        paragraph: string.isRequired,
        buttons: node.isRequired,
        children: node.isRequired,
        theme: object.isRequired,
        isDesktop: bool.isRequired,
        list: oneOfType([arrayOf(string), node]).isRequired,
        brokerInsert: string.isRequired,
        caveat: node,
    };

    static defaultProps = {
        caveat: undefined,
    };

    state = {
        showUSP: false,
    };

    toggleFeatures = showUSP => e => {
        Select(() =>
            this.setState({
                showUSP,
            })
        )(e);
    };

    createFeatureList() {
        return Array.isArray(this.props.list) ? (
            <FeatureList list={this.props.list} css={{ flexGrow: 1 }} />
        ) : (
            this.props.list
        );
    }

    createPricePanel() {
        const { title, paragraph, children, theme, isDesktop, brokerInsert, buttons } = this.props;
        return (
            <PricePanel isDesktop={isDesktop}>
                <PanelHeader>{title}</PanelHeader>
                <P css={{ marginBottom: theme.space_xs }}>
                    {paragraph}{' '}
                    {!isDesktop && (
                        <a
                            role="button"
                            tabIndex={0}
                            onClick={this.toggleFeatures(!this.state.showUSP)}
                            onKeyDown={this.toggleFeatures(!this.state.showUSP)}
                        >
                            {this.state.showUSP ? 'Hide' : 'Show'} features &amp; benefits
                        </a>
                    )}
                </P>
                {this.state.showUSP && !isDesktop ? (
                    this.createFeatureList()
                ) : (
                    <Fragment>
                        {children}
                        <glamorous.Div display="flex" alignItems="center" flexWrap="wrap" marginTop="auto" gap={6}>
                            {buttons}
                            <DocumentList stacked documents={[{ text: 'Broker insert', url: brokerInsert }]} />
                        </glamorous.Div>
                    </Fragment>
                )}
            </PricePanel>
        );
    }

    createFeaturesPanel() {
        return (
            <FeaturePanel isDesktop={this.props.isDesktop}>
                <PanelHeader>Features & benefits</PanelHeader>
                {this.createFeatureList()}
            </FeaturePanel>
        );
    }

    render() {
        const { caveat, isDesktop } = this.props;
        return (
            <div>
                <glamorous.Div display="flex" alignItems="stretch">
                    {this.createPricePanel()}
                    {isDesktop && this.createFeaturesPanel()}
                </glamorous.Div>
                {typeof caveat !== 'undefined' && caveat}
            </div>
        );
    }
}

export default withTheme(isDesktop(ResultsPanel));
