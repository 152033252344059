import React from 'react';
import glamorous from 'glamorous';
import { element, bool, string } from 'prop-types';
import { H2 } from '@marmalade/bling/lib/headers/default';
import Message from '@marmalade/message';
import isDesktop from '../../../../is-desktop';

const FormWrapper = glamorous('div')(({ isDesktop }) => ({
    margin: isDesktop ? 0 : '0 auto',
    maxWidth: '575px',
    width: isDesktop ? '48%' : undefined,
}));

Form.propTypes = {
    form: element.isRequired,
    isDesktop: bool.isRequired,
    error: string.isRequired,
};

function Form({ form, isDesktop, error }) {
    return (
        <FormWrapper isDesktop={isDesktop}>
            <H2>Get a quick quote</H2>
            {!!error.length && <Message type="error">{error}</Message>}
            {form}
        </FormWrapper>
    );
}

export default isDesktop(Form);
