import React, { Component } from 'react';
import glamorous from 'glamorous';
import { Link } from 'react-router-dom';

const LogoutDiv = glamorous('div')(({ theme }) => ({
    padding: `0 ${theme.space_md}`,
}));

const StyledLink = glamorous(Link)(({ theme }) => ({
    color: theme.colorWhite,
    ':hover': {
        color: theme.colorWhite,
    },
}));

class Logout extends Component {
    render() {
        return (
            <LogoutDiv>
                <StyledLink to="/logout">Logout</StyledLink>
            </LogoutDiv>
        );
    }
}

export default Logout;
