import React, { Component } from 'react';
import { sitemap } from '../prop-types';

// Higher order component to recieve sitemap context
export default function withSitemap(WrappedComponent) {
    return class WithSitemap extends Component {
        static propTypes = {
            sitemap,
        };

        static defaultProps = {
            sitemap: undefined,
        };

        static contextTypes = {
            sitemap,
        };

        static defaultContextTypes = {
            sitemap: undefined,
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    sitemap={this.props.sitemap ? this.props.sitemap : this.context.sitemap}
                />
            );
        }
    };
}
