import React from 'react';
import Page from '@marmalade/page';
import Header from 'marketing/components/header';
import { headerOptions } from '../constants';
import { node, func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { showModal } from '@marmalade/modal/lib/actions';
import Footer from 'marketing/components/footer';

Layout.propTypes = {
    children: node,
    showModal: func.isRequired,
    noLogin: bool,
    backgroundColor: string,
};

function Layout({ showModal, children, noLogin = false, backgroundColor }) {
    const onLogin = () => showModal('login');
    return (
        <Page backgroundColor={backgroundColor}>
            <Header options={headerOptions} onLogin={noLogin ? undefined : onLogin} />
            {children}
            <Footer />
        </Page>
    );
}

export default connect(
    undefined,
    { showModal }
)(Layout);
