import React, { Component } from 'react';
import glamorous, { Section, Div, withTheme, ThemeProvider } from 'glamorous';
import { connect } from 'react-redux';
import { bool, object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Panel } from '@marmalade/panel';
import { Container } from '@marmalade/grid';
import Button from '@marmalade/button';
import Form from '@marmalade/form/lib/components/form';
import Checkbox from '@marmalade/form/lib/components/checkbox/checkbox-stacked';
import { resetForm } from '@marmalade/form/lib/actions';
import componentThemes from '@marmalade/themes/lib/component-base-themes';
import { history } from '@marmalade/prop-types';
import ScrollToError from '@marmalade/form/lib/components/scroll-to-error';
import { isTablet } from 'selectors';
import Layout from 'marketing/components/layout';
import { H2 } from 'components/typography';
import validator from './validator';
import Hero from './hero';
import BrokerDetails from './broker-details';
import Options from './options';
import BankingDetails from './banking-details';
import registerBroker from './register-broker';
import { tobaEndpoint } from '../../../constants';

const dividerStyles = ({ theme }) => ({
    marginTop: 0,
    borderTop: `1px solid ${theme.colorGreyDark}`,
    opacity: 0.5,
});

const Divider = glamorous.hr(dividerStyles);

const disabledGroupStyles = ({ disabled = false }) => ({
    marginTop: '1rem',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : undefined,
    userSelect: disabled ? 'none' : undefined,
});

const DisabledGroup = glamorous.div(disabledGroupStyles);

class Register extends Component {
    static propTypes = {
        tablet: bool.isRequired,
        theme: object.isRequired,
        history: history.isRequired,
        resetForm: func.isRequired,
    };
    static defaultProps = {
        tablet: false,
    };
    state = {
        downloadedTOBA: false,
        submitting: false,
        error: false,
    };
    handleSubmit = form =>
        registerBroker({
            ...form,
            onValid: () => this.setState({ submitting: true }),
            onSuccess: () => {
                this.props.resetForm('register');
                this.props.history.push('/register-success');
            },
            onError: () => {
                this.setState({ submitting: false, error: true });
            },
        });

    downloadTOBA = e => {
        this.setState({ downloadedTOBA: true });
        window.open(tobaEndpoint);
    };

    render() {
        const { theme } = this.props;

        const { panelStyle } = componentThemes.panel;

        const panelTheme = {
            [panelStyle('backgroundColor')]: theme.colorWhite,
        };

        return (
            <ThemeProvider
                theme={{
                    ...panelTheme,
                    Page_backgroundColor: theme.colorOffWhite,
                }}
            >
                <Layout>
                    <Hero />
                    <Container>
                        <Section paddingTop="5rem" display="flex" justifyContent="center">
                            <Div maxWidth="66rem">
                                <Panel>
                                    <H2>Register today!</H2>
                                    <ScrollToError
                                        formId="register"
                                        type="danger"
                                        forceShowState={this.state.error}
                                        message={<span>An error occured, please contact us.</span>}
                                    />
                                    <Form id="register" handleSubmit={this.handleSubmit} validator={validator}>
                                        <BrokerDetails />
                                        <Options />
                                        <BankingDetails />
                                        <Div fontWeight="700" marginBottom="1rem">
                                            Please download a copy of our Terms of Business Agreement (TOBA) before
                                            registering
                                        </Div>
                                        <Button prominence="primary" onClick={this.downloadTOBA}>
                                            Download TOBA
                                        </Button>
                                        <DisabledGroup disabled={!this.state.downloadedTOBA}>
                                            <Checkbox
                                                name="TOBA"
                                                options={[
                                                    {
                                                        value: 'accept',
                                                        text:
                                                            'I confirm that I have read, downloaded and accepted the TOBA.',
                                                    },
                                                ]}
                                            />
                                            <Divider />
                                            <Div display="flex" justifyContent="flex-end">
                                                <Button
                                                    posting={this.state.submitting}
                                                    prominence="primary"
                                                    type="submit"
                                                >
                                                    Register
                                                </Button>
                                            </Div>
                                        </DisabledGroup>
                                    </Form>
                                </Panel>
                            </Div>
                        </Section>
                    </Container>
                </Layout>
            </ThemeProvider>
        );
    }
}

export default withTheme(
    withRouter(
        connect(
            (state, { theme }) => ({
                tablet: isTablet(theme)(state),
            }),
            { resetForm }
        )(Register)
    )
);
