import arrayToIso from '@marmalade/date/lib/array-to-iso';
import { actionTypes as T } from './constants';

export const sendBrokerAction = values => dispatch => {
    dispatch({
        auth: true,
        type: T.SEND_BROKER_ACTION,
        product: 'broker',
        request: {
            baseUrl: `${process.env.REACT_APP_API_BASE_URI}/broker/v1/broker-actions/create`,
            method: 'post',
            params: {
                ...values,
            },
        },
    });
    dispatch({ type: T.UPDATE_CURRENT_PRODUCT, payload: values.product });
};

export const sendEmailQuote = (product, values) => ({
    auth: true,
    type: T.SEND_EMAIL_QUOTE,
    product,
    request: {
        baseUrl: `${process.env.REACT_APP_API_BASE_URI}/${product}/v1/quick-quote/email`,
        method: 'post',
        params: {
            ...values,
            campaignKey: product === 'fdi' ? 'broker_fdi_quick_quote' : null,
        },
    },
});

export const submitForm = formState => dispatch => {
    if (formState.validAndPristine) {
        const callBack = formState.values.callback === 'Y' ? 1 : 0;
        if (!callBack) {
            dispatch(
                sendBrokerAction({
                    callBack,
                    product: formState.values.product,
                    customerEmail: formState.values.customerEmail,
                    quoteRef: formState.values.quoteRef,
                })
            );
        } else {
            dispatch(
                sendBrokerAction({
                    ...formState.values,
                    dayToCall: arrayToIso(formState.values.dayToCall),
                    callback: undefined,
                    callBack,
                })
            );
        }
        if (formState.values.customerEmail) {
            dispatch(
                sendEmailQuote(formState.values.product, {
                    quoteRef: formState.values.quoteRef,
                    email: formState.values.customerEmail,
                })
            );
        }
    }
};

export const reset = () => ({
    type: T.RESET,
});
