import React from 'react';
import { Panel } from '@marmalade/panel';
import { H2 } from '@marmalade/bling/lib/headers/default';
import MeetTeam from './meet-team';
import ContactDetails from './contact-details';

function ContactPanel() {
    return (
        <Panel
            css={{
                display: 'flex',
                flexFlow: 'row wrap',
                justifyContent: 'space-between',
            }}
        >
            <H2 css={{ width: '100%' }}>Get in touch</H2>
            <ContactDetails />
            <MeetTeam />
        </Panel>
    );
}

export default ContactPanel;
