import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, func, string } from 'prop-types';
import Message from '@marmalade/message';
import Form from '@marmalade/form/lib/components/form';
import FormNavigation from '@marmalade/form/lib/components/form-navigation';
import Email from '@marmalade/form/lib/questions/email';
import { isSubmittingForm } from '@marmalade/form/lib/selectors/form-info';
import oneByOne from '@marmalade/validation/lib/one-by-one';
import looksLikeEmail from '@marmalade/validation/lib/looks-like-email';
import mxLookup from '@marmalade/validation/lib/mx-lookup';
import assignEmail from './actions';
import { NAME } from './constants';

const formId = 'email-reset';

class EmailMadness extends Component {
    static propTypes = {
        posting: bool.isRequired,
        assignEmail: func.isRequired,
        error: string.isRequired,
    };

    handleSubmit = form => {
        if (!form.validAndPristine) return;
        this.props.assignEmail(form.values.email);
    };

    render() {
        return (
            <div style={{ maxWidth: 400 }}>
                <Form
                    id={formId}
                    handleSubmit={this.handleSubmit}
                    validator={{
                        email: oneByOne(looksLikeEmail(), mxLookup()),
                    }}
                >
                    <Email placeholder="" />
                    <FormNavigation
                        next={{
                            submit: true,
                            text: 'Submit',
                            posting: this.props.posting,
                        }}
                    />
                </Form>
                {this.props.error ? <Message type="error">{this.props.error}</Message> : null}
            </div>
        );
    }
}

export default connect(
    state => ({
        posting: isSubmittingForm(state, formId),
        error: state[NAME].error,
    }),
    { assignEmail }
)(EmailMadness);
