if (!window.YT) {
    window.YT = { loading: 0, loaded: 0 };
}
if (!window.YTConfig) {
    window.YTConfig = { host: 'http://www.youtube.com' };
}
if (!window.YT.loading) {
    window.YT.loading = 1;
    (function() {
        const l = [];
        window.YT.ready = function(f) {
            if (window.YT.loaded) {
                f();
            } else {
                l.push(f);
            }
        };
        window.onYTReady = function() {
            window.YT.loaded = 1;
            for (let i = 0; i < l.length; i++) {
                try {
                    l[i]();
                } catch (e) {}
            }
        };
        window.YT.setConfig = function(c) {
            for (const k in c) {
                if (c.hasOwnProperty(k)) {
                    window.YTConfig[k] = c[k];
                }
            }
        };
        const a = document.createElement('script');
        a.type = 'text/javascript';
        a.id = 'www-widgetapi-script';
        a.src = 'https://s.ytimg.com/yts/jsbin/www-widgetapi-vflOozvUR/www-widgetapi.js';
        a.async = true;
        const b = document.getElementsByTagName('script')[0];
        b.parentNode.insertBefore(a, b);
    })();
}

export default window.YT;
