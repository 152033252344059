import FeatureLink from '@marmalade/bling/lib/feature-link';
import PageWrapper from 'admin/components/page-wrapper';
import { withTheme } from 'glamorous';
import brokerPoster from 'media/posters/broker-poster.jpg';
import brokerPosterPdf from 'media/posters/broker-poster.pdf';
import ldiPoster from 'media/posters/ldi-poster.jpg';
import ldiPosterPdf from 'media/posters/ldi-poster.pdf';
import ndiPoster from 'media/posters/ndi-poster.jpg';
import ndiPosterPdf from 'media/posters/ndi-poster.pdf';
import nydPoster from 'media/posters/nyd-poster.jpg';
import nydPosterPdf from 'media/posters/nyd-poster.pdf';
import { number, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getWidth } from 'redux-window';
import Form from './form';

class Posters extends Component {
    static propTypes = {
        theme: object.isRequired,
        width: number.isRequired,
    };

    render() {
        const { theme } = this.props;
        const featureWidth = '50%';

        const featureLinkProps = {
            target: '_blank',
            css: { width: featureWidth },
            imgCss: { border: '2px solid #eee' },
            iconType: 'document-pdf',
            marginBottom: theme.space_md,
        };

        return (
            <PageWrapper css={{ width: '75%' }}>
                <h1>Posters</h1>
                <p>
                    Download our standard product posters or submit a request for your own poster with your logo. Just
                    fill in the details below and we will email you a personalised version.
                </p>
                <h3>Download posters</h3>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexFlow: 'row wrap',
                    }}
                >
                    <FeatureLink
                        title="Learner Driver"
                        imgSrc={ldiPoster}
                        alt="Learner Driver poster"
                        href={ldiPosterPdf}
                        {...featureLinkProps}
                    />
                    <FeatureLink
                        title="Black Box"
                        imgSrc={ndiPoster}
                        alt="Black Box poster"
                        href={ndiPosterPdf}
                        {...featureLinkProps}
                    />
                    <FeatureLink
                        title="Named Young Driver"
                        imgSrc={nydPoster}
                        alt="Named Young Driver poster"
                        href={nydPosterPdf}
                        {...featureLinkProps}
                    />
                    <FeatureLink
                        title="Broker Flyer"
                        imgSrc={brokerPoster}
                        alt="Broker Flyer"
                        href={brokerPosterPdf}
                        {...featureLinkProps}
                    />
                </div>
                <Form />
            </PageWrapper>
        );
    }
}

export default withTheme(
    connect(state => ({
        width: getWidth(state),
    }))(Posters)
);
