import React, { Component } from 'react';
import Icon from '@marmalade/icon';
import glamorous, { Section, Div, withTheme } from 'glamorous';
import { Container } from '@marmalade/grid';
import TestimonialRenderer from './testimonial-renderer';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { connect } from 'react-redux';
import { string, bool, arrayOf, shape } from 'prop-types';
import { isTablet, isMobile } from 'selectors';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const arrowStyles = ({ theme, small }) => ({
    fontSize: small ? undefined : '2.7rem',
    zIndex: 100,
    color: theme.colorPrimary,
    margin: small ? 0 : undefined,
    flexShrink: 0,
    cursor: 'pointer',
});

const Arrow = glamorous(Icon)(arrowStyles);

const arrowTextStyles = ({ theme }) => ({
    cursor: 'pointer',
    color: theme.colorPrimary,
    fontSize: '1em',
});

const ArrowText = glamorous.span(arrowTextStyles);

class CustomerTestimonials extends Component {
    static propTypes = {
        backgroundColor: string,
        testimonials: arrayOf(
            shape({
                image: string.isRequired,
                customer: string.isRequired,
                testimonial: string.isRequired,
            })
        ).isRequired,
        tablet: bool.isRequired,
        mobile: bool.isRequired,
    };
    state = {
        index: 0,
    };
    goBack = () => {
        this.setState(state => ({
            index: state.index - 1,
        }));
    };
    goForward = () => {
        this.setState({
            index: this.state.index + 1,
        });
    };
    go = index => {
        this.setState({
            index,
        });
    };
    render() {
        const { backgroundColor, testimonials, tablet, mobile } = this.props;
        return (
            <Section backgroundColor={backgroundColor}>
                <Container>
                    <Div textAlign="center" marginTop="7.5rem" marginBottom="11.5rem">
                        <h2>What our customers say...</h2>
                        <glamorous.Div display="flex" alignItems="center">
                            {!tablet && <Arrow type="arrow-left" onClick={this.goBack} />}
                            <VirtualizeSwipeableViews
                                index={this.state.index}
                                onChangeIndex={this.go}
                                slideRenderer={TestimonialRenderer(testimonials, mobile)}
                            />
                            {!tablet && <Arrow type="arrow-right" onClick={this.goForward} />}
                        </glamorous.Div>
                        {tablet && (
                            <glamorous.Div display="flex" justifyContent="space-between">
                                <glamorous.Div display="flex" alignItems="center">
                                    <Arrow small type="arrow-left" onClick={this.goBack} />
                                    <ArrowText onClick={this.goBack}>Previous</ArrowText>
                                </glamorous.Div>
                                <glamorous.Div display="flex" alignItems="center">
                                    <ArrowText onClick={this.goForward}>Next</ArrowText>
                                    <Arrow small type="arrow-right" onClick={this.goForward} />
                                </glamorous.Div>
                            </glamorous.Div>
                        )}
                    </Div>
                </Container>
            </Section>
        );
    }
}

export default withTheme(
    connect((state, { theme }) => ({
        mobile: isMobile(theme)(state),
        tablet: isTablet(theme)(state),
    }))(CustomerTestimonials)
);
