import React from 'react';
import { object, string } from 'prop-types';
import glamorous, { withTheme } from 'glamorous';
import { NavLink } from 'react-router-dom';
import MenuItem, { menuItemWrapperStyles } from './menu-item';

const Link = glamorous(NavLink)(menuItemWrapperStyles);

MenuNavLink.propTypes = {
    theme: object.isRequired,
    to: string.isRequired,
};

function MenuNavLink({ theme, to, ...rest }) {
    return (
        <Link
            to={to}
            activeStyle={{
                backgroundColor: theme.colorWhite,
                color: theme.colorBlack,
                borderWidth: 0,
            }}
        >
            <MenuItem {...rest} />
        </Link>
    );
}

export default withTheme(MenuNavLink);
