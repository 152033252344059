import React from 'react';
import { Panel } from '@marmalade/panel';

const PageNotFound = () => (
    <Panel>
        <p className="lead">Sorry, page not found</p>
    </Panel>
);

export default PageNotFound;
