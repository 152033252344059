import { getGuid } from '@marmalade/account/lib/selectors';
import Button, { ButtonGroup, LinkButton } from '@marmalade/button';
import domain from '@marmalade/domain';
import { getQuotes, quoteRef } from '@marmalade/fdi/lib/quick-quote/selectors';
import { showModal } from '@marmalade/modal/lib/actions';
import glamorous from 'glamorous';
import { func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import urls from '../../../urls';
import AnnualOrMonthlyPrices from '../components/results/annual-or-monthly-prices';
import ResultsPanel from '../components/results/results-panel';
import Caveats from './caveats';
import { formId } from './form';

const QuoteWrapper = glamorous('div')(({ theme }) => ({
    marginBottom: theme.space_sm,
}));

class FDIResultsPanel extends Component {
    static propTypes = {
        price: object.isRequired,
        showModal: func.isRequired,
        quoteRef: string.isRequired,
        guid: string.isRequired,
    };

    openModal = () => {
        this.props.showModal('callback', undefined, {
            quoteRef: this.props.quoteRef,
            product: 'fdi',
        });
    };

    render() {
        const { price, quoteRef, guid } = this.props;
        return (
            <React.Fragment>
                <h3>Your customer’s guide prices for Named Young Driver Insurance</h3>
                <ResultsPanel formId={formId} title="Annual insurance">
                    <QuoteWrapper>
                        <AnnualOrMonthlyPrices {...price} />
                        <ButtonGroup>
                            <Button
                                Element="a"
                                href={`${domain(
                                    'familycar.wearemarmalade'
                                )}/fdi/new/stage1?quoteRef=${quoteRef}&guid=${guid}`}
                                target="_blank"
                                prominence="primary"
                            >
                                Proceed to full quote
                            </Button>
                            <Button text="Request call back" prominence="primary" onClick={this.openModal} />
                            <LinkButton to={urls.fdi}>Get a new quote</LinkButton>
                        </ButtonGroup>
                    </QuoteWrapper>
                    <Caveats />
                </ResultsPanel>
            </React.Fragment>
        );
    }
}

export default connect(
    state => ({
        price: getQuotes(state)[10000],
        quoteRef: quoteRef(state),
        guid: getGuid(state),
    }),
    { showModal }
)(FDIResultsPanel);
