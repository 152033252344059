import React from 'react';
import { object } from 'prop-types';
import { withTheme } from 'glamorous';
import List from '@marmalade/bling/lib/list';
import ContactListItem from './contact-list-item';

ContactList.propTypes = {
    theme: object.isRequired,
};

function ContactList({ theme }) {
    return (
        <List
            type="none"
            css={{ margin: 0 }}
            list={[
                {
                    text: theme.email,
                    link: 'mailto:' + theme.email,
                    icon: 'envelope',
                },
                {
                    text: theme.telephone,
                    link: 'tel:' + theme.telephone,
                    icon: 'cordless',
                },
            ].map((item, index) => (
                <ContactListItem key={index} {...item} />
            ))}
        />
    );
}

export default withTheme(ContactList);
