import React from 'react';
import { string, bool } from 'prop-types';
import { product } from '@marmalade/prop-types';
import { H3 } from '@marmalade/bling/lib/headers/default';
import Button from '@marmalade/button';
import Text from '../../../../components/grey-paragraph';
import PanelWithIcon from '../panel-with-icon';

CTABlock.propTypes = {
    title: string.isRequired,
    text: string.isRequired,
    cta: {
        text: string,
        link: string,
    },
    newPage: bool,
    product,
};

CTABlock.defaultProps = {
    product: undefined,
};

function CTABlock({ title, text, cta, newPage, product }) {
    return (
        <PanelWithIcon icon="renew">
            <H3>{title}</H3>
            <Text>{text}</Text>
            {cta && (
                <Button prominence="secondary" Element="a" target={newPage ? '_blank' : '_self'} href={cta.link}>
                    {cta.text}
                </Button>
            )}
        </PanelWithIcon>
    );
}

export default CTABlock;
