import React, { Component } from 'react';
import { string, node, arrayOf, object, bool, element, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { product, history } from '@marmalade/prop-types';
import { Panel } from '@marmalade/panel';
import PageWrapper from 'admin/components/page-wrapper';
import isDesktop from '../../../../is-desktop';
import ExtraPanel from '../../../../components/extra-panel';
import Hero from './hero';
import CTABlock from './cta-block';
import LDIAccountPanel from './ldi-account-panel';
import FeatureList from '../features-tick-list';
import Form from './form';
import ExtraPanelWrapper from './extra-panel-wrapper';

class GetAQuote extends Component {
    static propTypes = {
        title: string.isRequired,
        lead: node.isRequired,
        image: string.isRequired,
        list: arrayOf(string).isRequired,
        theme: object.isRequired,
        product: product.isRequired,
        renewalMessage: string.isRequired,
        documents: element.isRequired,
        isDesktop: bool.isRequired,
        form: element.isRequired,
        error: string.isRequired,
        quoted: bool.isRequired,
        results: string.isRequired,
        history: history.isRequired,
        reset: func.isRequired,
    };

    componentDidMount() {
        if (this.props.quoted) {
            this.props.reset();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.quoted && nextProps.quoted) {
            this.props.history.push(this.props.results);
        }
    }

    render() {
        const { title, lead, image, list, product, documents, theme, isDesktop, form, error } = this.props;
        return (
            <div>
                <Hero title={title} lead={lead} image={image} />
                <PageWrapper>
                    <Panel
                        noBorder
                        css={{
                            padding: theme.space_md,
                            ...(isDesktop
                                ? {
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                  }
                                : {}),
                        }}
                    >
                        <Form form={form} error={error} />
                        {isDesktop && (
                            <FeatureList
                                list={list}
                                css={{
                                    width: '48%',
                                    flexGrow: 0,
                                    marginTop: '96px',
                                }}
                            />
                        )}
                    </Panel>
                    {!isDesktop && <FeatureList list={list} />}
                    <ExtraPanelWrapper>
                        {product === 'ldi' ? (
                            <LDIAccountPanel />
                        ) : (
                            <CTABlock
                                title="Marketing Support"
                                text="Don't forget to take a look at our Marketing area for promotional materials from online adverts, to posters and leaflets!"
                            />
                        )}
                        <ExtraPanel>{documents}</ExtraPanel>
                    </ExtraPanelWrapper>
                </PageWrapper>
            </div>
        );
    }
}

export default withRouter(isDesktop(GetAQuote));
