import userGuide from 'media/broker-help/ndi-user-guide.pdf';
import brokerInsert from 'media/inserts/ndi-insert.pdf';
import React from 'react';
import Documents from '../components/documents';

function NDIDocuments({ showQuickQuoteGuide, showPolicyBooklet, underwriterId }) {
    const ndiDocs = [{ text: 'Broker insert', url: brokerInsert }];

    if (showPolicyBooklet) {
        ndiDocs.push({
            text: 'Policy booklet',
            url: `${process.env.REACT_APP_API_BASE_URI}/proxy/document.php?product=2&documentType=2&underwriter=${underwriterId}&return=download`,
        });
    }

    if (showQuickQuoteGuide) {
        ndiDocs.push({ text: 'Quick Quote guide', url: userGuide });
    }

    return (
        <Documents
            text="Please download our PDF's covering key policy information and helpful guides for our quick quote sales process."
            documents={ndiDocs}
        />
    );
}

export default NDIDocuments;
