import React from 'react';
import { Div } from 'glamorous';
import { number, node } from 'prop-types';

Box.propTypes = {
    aspectRatio: number,
    children: node,
};

function Box({ aspectRatio = 1, children, ...rest }) {
    return (
        <Div width="100%" position="relative" paddingBottom={`${(1 / aspectRatio) * 100}%`} maxHeight="100%" {...rest}>
            <Div position="absolute" top="0" bottom="0" left="0" right="0">
                {children}
            </Div>
        </Div>
    );
}

export default Box;
