import React from 'react';
import { object, bool } from 'prop-types';
import glamorous from 'glamorous';
import isDesktop from '../../is-desktop';
import H1 from '../../components/h1';
import Lead from '../quick-quotes/components/lead';
import hero from './images/header.jpg';

const HeroWrapper = glamorous('div')(({ theme, isDesktop }) => ({
    backgroundImage: `url(${hero})`,
    color: theme.colorWhite,
    textAlign: 'center',
    padding: isDesktop ? theme.space_lg : theme.space_md,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '360px',
}));

Hero.propTypes = {
    theme: object.isRequired,
    isDesktop: bool.isRequired,
};

function Hero({ theme, isDesktop }) {
    return (
        <HeroWrapper isDesktop={isDesktop}>
            <H1>Contact broker support team</H1>
            <Lead>
                We're on hand to assist and support with any young driver enquiry and help you increase your share of
                the young driver market.
            </Lead>
            <Lead>
                Drop us an{' '}
                <strong>
                    <a href={`mailto:${theme.email}`}>email</a>
                </strong>{' '}
                or give us a call on{' '}
                <strong>
                    <a href={`tel:${theme.telephone}`}>{theme.telephone}</a>
                </strong>
            </Lead>
        </HeroWrapper>
    );
}

export default isDesktop(Hero);
