import { componentThemes } from '@marmalade/themes';
const { headerStyle } = componentThemes.header;

const theme = baseTheme => ({
    pageWidthLimit: 1600,
    [headerStyle('colorSecondary')]: baseTheme.colorWhite,
    [headerStyle('colorIcon')]: baseTheme.colorWhite,
    colorGreyHover: '#262626',
});

export default theme;
