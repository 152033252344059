import MichaelFoord from 'marketing/media/testimonial-headshots/michael-foord.png';
import Policywise from 'marketing/media/testimonial-headshots/policywise.png';
import CarbonInsurance from 'marketing/media/testimonial-headshots/carbon-insurance.png';
import APlanImage from 'marketing/media/partners/a-plan.jpg';
import CarbonImage from 'marketing/media/partners/carbon.jpg';
import HenshallsImage from 'marketing/media/partners/henshalls.jpg';
import PolicywiseImage from 'marketing/media/partners/policywise.jpg';
import PWWhite from 'marketing/media/partners/pw-white.jpg';
import WestCraven from 'marketing/media/partners/west-craven.jpg';

export const testimonials = [
    {
        image: MichaelFoord,
        testimonial:
            'Our dealings with Marmalade have been nothing but refreshing. So quick to react when something needs tweaking or if on the rare occasion we have a problem. Their systems are modern, slick and efficient, and considering the volumes, any client issues/concerns are extremely low.',
        customer: 'Michael Foord, Branch Manager at A-Plan',
    },
    {
        image: Policywise,
        testimonial:
            'Having Marmalade products available provides us with an additional choice with a range of products to enable us to quote for risks that previously we may not have been able to. The system is very easy to use, with the quick quote system allowing us to get quotes quickly and effectively.',
        customer: 'Nicolle Bradbury, Insurance consultant, Policywise',
    },
    {
        image: CarbonInsurance,
        testimonial:
            'The Marmalade products are fantastic as we can now cater for all young/new driver enquiries. Before Marmalade we were constantly turning enquiries away as we could not place the business anywhere. The system is a user friendly, which gives step by step instructions on how to quote or simply share your links with potential clients',
        customer: 'Stacy Shillito, Broker Manager, Carbon Insurance Brokers',
    },
];

export const partnerView = {
    aspect: 4 / 7,
    border: '1px solid #D2D5D7',
};

export const partners = [
    { src: APlanImage, bg: '#fff' },
    { src: WestCraven, bg: '#fff' },
    { src: PWWhite, bg: '#fff' },
    { src: PolicywiseImage, bg: '#fff' },
    { src: HenshallsImage, bg: '#fff' },
    { src: CarbonImage, bg: '#fff' },
];
