import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, string, bool, object } from 'prop-types';
import { withTheme, ThemeProvider } from 'glamorous';
import Form from '@marmalade/multi-quote/lib/quick-quote/components/form-shell';
import Age from '@marmalade/multi-quote/lib/quick-quote/components/age';
import { quoting } from '@marmalade/multi-quote/lib/quick-quote/selectors';
import FormNavigation from '@marmalade/form/lib/components/form-navigation';
import { RadioYesNo } from '@marmalade/form/lib/components/radio';
import { resetFields } from '@marmalade/form/lib/actions/update-fields';
import { getValue } from '@marmalade/form/lib/selectors/field-info';
import { componentThemes } from '@marmalade/themes';
import VehicleLookup from '../components/quote/vehicle-lookup';
import LicenceHeld from '../components/quote/licence-held';
import Postcode from '../components/quote/postcode';
import BrokerAge from '../components/quote/age';
import withGUID from '../../../with-guid';
import BrokerTypeOfLicence from '../components/quote/type-of-licence';

const { form } = componentThemes;

export const formId = 'multiQuickQuote';

class MultiForm extends Component {
    static propTypes = {
        resetFields: func.isRequired,
        showLicenceHeld: bool.isRequired,
        guid: string.isRequired,
        showForm: bool,
        posting: bool.isRequired,
        theme: object.isRequired,
    };

    static defaultProps = {
        showForm: false,
    };

    resetLicenceHeldTime = () => {
        this.props.resetFields(formId, ['licenceHeld']);
    };

    render() {
        return (
            <ThemeProvider theme={form.offWhiteInputTheme(this.props.theme)}>
                <Form formId={formId} guid={this.props.guid}>
                    <VehicleLookup disableManualLookup />
                    <Postcode disabled={!this.props.showForm} />
                    <BrokerAge disabled={!this.props.showForm} Age={Age} />
                    <BrokerTypeOfLicence disabled={!this.props.showForm} onChange={this.resetLicenceHeldTime} />
                    {this.props.showLicenceHeld && <LicenceHeld />}
                    <RadioYesNo
                        name="mainDriver"
                        label="Will the young driver be the main driver of the car?"
                        disabled={!this.props.showForm}
                    />
                    <FormNavigation
                        next={{
                            submit: true,
                            text: 'Get a quote',
                            icon: false,
                            disabled: !this.props.showForm,
                            posting: this.props.posting,
                        }}
                    />
                </Form>
            </ThemeProvider>
        );
    }
}

export default withTheme(
    withGUID(
        connect(
            state => ({
                showForm: !!getValue(state, formId, 'abiCode'),
                showLicenceHeld: getValue(state, formId, 'licenceType') === 'F',
                posting: quoting(state),
            }),
            {
                resetFields,
            }
        )(MultiForm)
    )
);
