import React, { Fragment } from 'react';
import SmallGreyText from '../components/small-grey-text';

function Caveats() {
    return (
        <Fragment>
            <SmallGreyText>
                This is not a formal quote. A full quote will be provided to the customer by a Marmalade advisor if the
                customer chooses to proceed.
                <br />
                The policy is designed for the customer to be an additional driver of the car, covering Social,
                Domestic, Pleasure and Commuting, up to 10,000 miles per year (without exceeding 50% of the trips taken
                in the car). Please note the price quoted is an indicative quote based on the limited information
                provided and is subject to change upon completion of a full quote.
            </SmallGreyText>
        </Fragment>
    );
}

export default Caveats;
