import React from 'react';
import Layout from 'marketing/components/layout';
import { connect } from 'react-redux';
import Hero from 'marketing/components/hero';
import image from 'marketing/media/broker-team.jpg';
import imageTablet from 'marketing/media/broker-team-cropped.jpg';
import imageMobile from 'marketing/media/broker-team-mobile.jpg';
import glamorous, { P, Div, withTheme, Section, Img } from 'glamorous';
import { Container } from '@marmalade/grid';
import { constants as contact } from '@marmalade/contact';
import { bool } from 'prop-types';
import { isMobile, isTablet } from 'selectors';
import { H1, H2 } from 'components/typography';
import { Reviews } from '@marmalade/trust-pilot';

const copyStyles = ({ theme, children, ...rest }) => ({
    color: theme.colorGrey,
    ...rest,
});

const Copy = glamorous.p(copyStyles);

AboutUs.propTypes = {
    mobile: bool.isRequired,
    tablet: bool.isRequired,
};

function AboutUs({ mobile, tablet }) {
    return (
        <Layout>
            <Hero
                background={
                    mobile
                        ? 'linear-gradient(#F3B263, #f5763e)'
                        : `url(${
                              tablet ? imageTablet : image
                          }) no-repeat right center, linear-gradient(#f5bb5e, #F48D51)`
                }
                backgroundSize="auto 100%"
                color="#f9f9f9"
                angle={0}
            >
                <Container>
                    <Div
                        height={tablet ? '25rem' : '40rem'}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <H1 marginTop="7rem" marginBottom="2.4rem">
                            Helping parents and young drivers
                        </H1>
                        <P marginBottom="7rem" fontSize="2rem" maxWidth="50rem">
                            We're the champions for young driver cover on a parent’s car
                        </P>
                    </Div>
                </Container>
            </Hero>
            <Section backgroundColor="#f9f9f9" paddingBottom="10rem">
                <Container>
                    <H2 marginTop="4.6rem">About us</H2>
                    <Copy>
                        Getting a young driver on the road can be a daunting prospect for both the young person and
                        their parents. Marmalade are the champions of young driver cover on a parent’s car, with getting
                        young drivers on the road the top priority- giving them the opportunity to not only get insured
                        on a family car, or in their own car, but to be safer behind the wheel as well. Established in
                        2006, Marmalade are continually looking for innovative solutions to help make the journey easier
                        for parents looking to get their young driver on the road both before and after they pass their
                        test.
                    </Copy>
                    <Copy>
                        <b>How can Marmalade help you to help your customers?</b>
                    </Copy>
                    <Copy>
                        Marmalade is with you every step of the way as you grow your business within the young driver
                        and family car sharing market. We could help you increase your share of this market and earn
                        commissions of up to <b>£100 per customer!</b>
                    </Copy>
                    <Copy>
                        Our friendly broker team are on-hand to offer support and assistance as you strive to increase
                        your share of the young driver market. You can call us on{' '}
                        <b>
                            <a href={`tel:${contact.phone.broker.split(' ').join('')}`}>{contact.phone.broker}</a>
                        </b>
                        , or <a href={`mailto:${contact.email.broker}`}>{contact.email.broker}</a>, if you ever have any
                        questions.
                    </Copy>
                    {mobile && (
                        <Div display="flex" justifyContent="center" paddingTop="3rem">
                            <Img alt="Marmalade broker team" src={imageMobile} width="100%" maxWidth="33rem" />
                        </Div>
                    )}
                </Container>
            </Section>
            <Section margin="9rem 0">
                <Container>
                    <Reviews />
                </Container>
            </Section>
        </Layout>
    );
}

export default withTheme(
    connect((state, { theme }) => ({
        mobile: isMobile(theme)(state),
        tablet: isTablet(theme)(state),
    }))(AboutUs)
);
