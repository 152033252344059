import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { object, func, bool } from 'prop-types';
import { withTheme } from 'glamorous';
import { Panel } from '@marmalade/panel';
import Button from '@marmalade/button';
import Form from '@marmalade/form/lib/components/form';
import FormNavigation from '@marmalade/form/lib/components/form-navigation';
import Radio from '@marmalade/form/lib/components/radio';
import { resetForm } from '@marmalade/form/lib/actions/update-form';
import Hr from '@marmalade/bling/lib/hr';
import required from '@marmalade/validation/lib/required';
import { logoState, actions } from './save-logo';
import UploadLogo from './upload-logo';

class RequestForm extends Component {
    static propTypes = {
        theme: object.isRequired,
        notified: bool.isRequired,
        notifying: bool.isRequired,
        notify: func.isRequired,
        clearLogo: func.isRequired,
        resetForm: func.isRequired,
    };

    static defaultProps = {
        fieldError: undefined,
    };

    handleSubmit = form => {
        if (!form.validAndPristine) return;
        this.props.notify(form.values);
    };

    reset = () => {
        this.props.clearLogo();
        this.props.resetForm('posters');
    };

    renderForm = () => {
        return (
            <Form
                id="posters"
                validator={{
                    product: required('Please select the product you require posters for'),
                    fileName: required('Please upload your image'),
                }}
                handleSubmit={this.handleSubmit}
            >
                <p>
                    Submit a request for your own poster to print for your office. Just fill in the details below and we
                    will email you a personalised version. Please provide a hi-res logo to ensure your logo isn't
                    pixelated when printed.
                </p>
                <UploadLogo />
                <Radio
                    name="product"
                    label="Poster required"
                    options={[
                        {
                            text: 'Black Box',
                            value: 'ndi',
                        },
                        {
                            text: 'Named Driver',
                            value: 'fdi',
                        },
                        {
                            text: 'Learner Driver',
                            value: 'ldi',
                        },
                        {
                            text: 'All',
                            value: 'all',
                        },
                    ]}
                />
                <Hr />
                <FormNavigation
                    next={{
                        submit: true,
                        text: 'Request poster',
                        icon: false,
                        posting: this.props.notifying,
                    }}
                />
            </Form>
        );
    };

    render() {
        const { notified } = this.props;
        return (
            <Panel>
                <h3>Request a personalised poster</h3>
                {notified ? (
                    <Fragment>
                        <p>Thank you, your poster will be sent within 5 working days.</p>
                        <Button onClick={this.reset} text="Request another" />
                    </Fragment>
                ) : (
                    this.renderForm()
                )}
            </Panel>
        );
    }
}

export default connect(
    state => ({
        notified: logoState(state).notified,
        notifying: logoState(state).notifying,
    }),
    {
        clearLogo: actions.clearLogo,
        notify: actions.notify,
        resetForm,
    }
)(withTheme(RequestForm));
