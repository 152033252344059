import React from 'react';
import { string, node } from 'prop-types';
import glamorous from 'glamorous';
import { primaryIconComponent } from '@marmalade/icon';
import ExtraPanel from '../../../components/extra-panel';

const Icon = glamorous(primaryIconComponent('5rem'))(({ theme }) => ({
    marginRight: theme.space_md,
    flexShrink: 0,
}));

const Panel = glamorous(ExtraPanel)(({ theme }) => ({
    display: 'flex',
}));

PanelWithIcon.propTypes = {
    icon: string.isRequired,
    children: node.isRequired,
};

function PanelWithIcon({ icon, children }) {
    return (
        <Panel>
            <Icon type={icon} />
            <div>{children}</div>
        </Panel>
    );
}

export default PanelWithIcon;
