import glamorous from 'glamorous';
import { number, string } from 'prop-types';
import React from 'react';
import SmallText from '../../components/small-grey-text';

const PriceWrapper = glamorous('p')(({ theme }) => ({
    marginBottom: theme.space_xs,
}));
const BoldPriceWrapper = glamorous(PriceWrapper)(({ theme }) => ({
    fontWeight: theme.fontBold,
}));
const Price = glamorous('span')(({ theme }) => ({
    fontSize: '30px',
}));
const SmallGreyText = glamorous(SmallText)(({ theme }) => ({
    marginBottom: theme.space_sm,
}));
const Wrapper = glamorous('div')(({ theme }) => ({
    marginBottom: theme.space_sm,
}));

AnnualOrMonthlyResults.propTypes = {
    monthly: string.isRequired,
    annual: string.isRequired,
    totalPayable: string.isRequired,
    apr: number.isRequired,
    months: number.isRequired,
    deposit: string,
    totalPayableMonthly: string,
    facilityFee: string,
    firstInstalment: string,
    adminFee: string,
};

AnnualOrMonthlyResults.defaultProps = {
    deposit: undefined,
};

function AnnualOrMonthlyResults({
    monthly,
    annual,
    totalPayable,
    totalPayableMonthly,
    months,
    apr,
    deposit,
    facilityFee,
    firstInstalment,
    adminFee,
}) {
    return (
        <Wrapper>
            <BoldPriceWrapper>
                {firstInstalment && (
                    <React.Fragment>1 monthly payment of &pound;{firstInstalment} followed by&nbsp;</React.Fragment>
                )}
                {firstInstalment ? months - 1 : months} monthly payments of:
                <br />
                <Price>£{monthly}</Price>
            </BoldPriceWrapper>
            {typeof deposit !== 'undefined' && (
                <PriceWrapper>
                    With a deposit of: <strong>&pound;{deposit}</strong>
                </PriceWrapper>
            )}
            <SmallGreyText>
                Total amount payable over the term:{' '}
                <strong>&pound;{totalPayableMonthly ? totalPayableMonthly : totalPayable}</strong>
                {facilityFee && <React.Fragment>&nbsp;Including a &pound;{facilityFee} facility fee</React.Fragment>}
                <br />({apr}% APR Representative)
            </SmallGreyText>
            <BoldPriceWrapper>Or an annual premium of: £{annual}</BoldPriceWrapper>
            <SmallGreyText>{`(including Insurance Premium Tax where applicable${adminFee ? ` and an administration fee of £${adminFee}` : ''})`}</SmallGreyText>
        </Wrapper>
    );
}

export default AnnualOrMonthlyResults;
