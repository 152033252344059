import React from 'react';
import Level from '@marmalade/bling/lib/level';
import { object, arrayOf, string } from 'prop-types';
import { Section } from 'glamorous';
import { Container } from '@marmalade/grid';
import Feature from 'marketing/components/feature';

Lead.propTypes = {
    features: arrayOf(object),
    backgroundColor: string,
};

function Lead({ features, backgroundColor }) {
    return (
        <Section backgroundColor={backgroundColor}>
            <Container>
                <Level breakpoint="md">
                    {features.map((feature, idx) => (
                        <Feature key={idx} {...feature} />
                    ))}
                </Level>
            </Container>
        </Section>
    );
}

export default Lead;
