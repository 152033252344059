import { MarmaladeThemeProvider } from '@marmalade/themes';
import * as Sentry from '@sentry/browser';
import 'airbnb-js-shims';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { resizeEvent } from 'redux-window';
import Router from './router';
import store from './store';
import theme from './theme';

resizeEvent(store);

Sentry.init({
    dsn:
        'https://6cdca36ceee84e3d8f078f87a8a7399d@sentry.marmaladesoftware.co.uk/18',
    environment: process.env.REACT_APP_DEPLOY_ENVIRONMENT || 'dev',
    allowUrls: [
        'https://www.marmaladebroker.co.uk',
        /https?:\/\/(assets|broker)\.wearemarmalade(-[a-z]+)?\.(co\.uk|vm)/
    ]
});

render(
    <Provider store={store}>
        <MarmaladeThemeProvider subTheme={theme}>
            <Router />
        </MarmaladeThemeProvider>
    </Provider>,
    document.getElementById('root')
);
