import React from 'react';
import { string, node, bool, object } from 'prop-types';
import glamorous from 'glamorous';
import isDekstop from '../../../../is-desktop';
import Lead from '../lead';
import H1 from './h1';

Hero.propTypes = {
    title: string.isRequired,
    lead: node.isRequired,
    image: string.isRequired,
    theme: object.isRequired,
    isDesktop: bool.isRequired,
    css: object,
};

Hero.defaultProps = {
    css: undefined,
};

function Hero({ title, lead, image, isDesktop, theme, css }) {
    return (
        <glamorous.Div
            backgroundImage={isDesktop ? `url(${image})` : undefined}
            backgroundSize="cover"
            backgroundPosition="center"
            minHeight={isDesktop ? '370px' : 'auto'}
            css={css}
        >
            {!isDesktop && <glamorous.Img src={image} display="block" />}
            <glamorous.Div
                padding={isDesktop ? `${theme.space_lg} ${theme.space_md}` : theme.space_sm}
                color={isDesktop ? theme.colorWhite : undefined}
                maxWidth={isDesktop ? '65%' : 'auto'}
            >
                <H1>{title}</H1>
                <Lead>{lead}</Lead>
            </glamorous.Div>
        </glamorous.Div>
    );
}

export default isDekstop(Hero);
