import React from 'react';
import userGuide from 'media/broker-help/ldi-user-guide.pdf';
import brokerInsert from 'media/inserts/ldi-insert.pdf';
import Documents from '../components/documents';

function LDIDocuments() {
    return (
        <Documents
            text="Please download our PDF's covering key policy information and helpful guides for our portal."
            documents={[
                { text: 'Broker insert', url: brokerInsert },
                {
                    text: 'Policy booklet',
                    url: `${
                        process.env.REACT_APP_API_BASE_URI
                    }/proxy/document.php?product=1&documentType=3&return=download`,
                },
                { text: 'User guide', url: userGuide },
            ]}
        />
    );
}

export default LDIDocuments;
