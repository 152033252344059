import React from 'react';
import glamorous, { Div } from 'glamorous';
import { number, func } from 'prop-types';

const gridWrapperStyles = ({ theme, rows }) => ({
    width: `${100 / rows}%`,
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
        flexGrow: 1,
        margin: '1rem',
    },
});

const GridWrapper = glamorous.div(gridWrapperStyles);

Grid.propTypes = {
    rows: number,
    children: func.isRequired,
};

function Grid({ rows, children, ...rest }) {
    return (
        <Div display="flex" flexWrap="wrap" margin="0 -1rem" {...rest}>
            {children((jsx, key) => (
                <GridWrapper rows={rows} key={key}>
                    {jsx}
                </GridWrapper>
            ))}
        </Div>
    );
}

export default Grid;
