import React from 'react';
import { object, arrayOf, string } from 'prop-types';
import { withTheme } from 'glamorous';
import List from '@marmalade/bling/lib/list';

FeatureList.propTypes = {
    theme: object.isRequired,
    list: arrayOf(string).isRequired,
    css: object,
};

FeatureList.defaultProps = {
    css: undefined,
};

function FeatureList({ theme, list, css }) {
    return <List list={list} type="tick" color="primary" liCss={{ color: theme.colorGrey }} css={css} />;
}

export default withTheme(FeatureList);
