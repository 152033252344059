import glamorous from 'glamorous';
import { atLeast } from '@marmalade/themes';

const H1 = glamorous('h1')(({ theme }) => ({
    fontSize: '3.6rem',
    fontWeight: 900,
    marginBottom: theme.space_sm,
    [atLeast(theme.breakpoint_lg)]: {
        fontSize: '4.6rem',
    },
}));

export default H1;
