import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { loadState, saveState } from '@marmalade/session';
import authReducer from '@marmalade/account/lib/auth-reducer';
import authMiddleware from '@marmalade/account/lib/auth-middleware';
import reducer from './root-reducer';

const initialState = loadState('1.0');

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const store = createStore(
    authReducer(reducer),
    initialState,
    composeEnhancers(applyMiddleware(thunk, authMiddleware, promiseMiddleware()))
);

saveState(store, '1.0', state => ({
    account: state.account,
    form: state.form,
    markerStudyOptions: state.markerStudyOptions,
    ldiQuote: state.ldiQuote,
    fdiQuickQuote: state.fdiQuickQuote,
    ndiQuickQuote: state.ndiQuickQuote,
    multiQuickQuote: state.multiQuickQuote,
}));

export default store;
