import axios from 'axios';

export default ({ validAndPristine, values, onValid, onError, onSuccess }) => {
    if (!validAndPristine) return;

    const address = values.addressLine2 ? [values.addressLine1, values.addressLine2].join(', ') : values.addressLine1;

    const requestObject = {
        title: values.title,
        firstName: values.firstName,
        surname: values.lastName,
        company: values.companyName,
        address,
        town: values.addressTownCity,
        county: values.addressCounty,
        postcode: values.addressPostcode,
        telephone: values.work,
        mobile: values.mobile,
        email: values.email,
        network: values.network,
        linkTo: values.linkTo === 'Yes' ? 1 : 0, // RadioYesNo does this for you (but the back end needs to change to use Y/N not 1/0)
        largerGroup: values.largerGroup === 'Yes' ? 1 : 0, // See line above
        fcaNumber: values.fcaNumber,
        url: values.url,
        bankName: values.bankName,
        sortCode: values.sortCode.join('-') === '--' ? undefined : values.sortCode.join('-'),
        bankAccount: values.accountNumber,
    };

    onValid();

    axios
        .post(`${process.env.REACT_APP_API_BASE_URI}/broker/v1/register-broker`, requestObject)
        .then(onSuccess)
        .catch(onError);
};
