import React, { Fragment } from 'react';
import SmallGreyText from '../components/small-grey-text';

function Caveats() {
    return (
        <Fragment>
            <p>
                When proceeding you will be taken to the learner driver portal. Please log in as an{' '}
                <strong>Agent</strong> and <strong>NOT</strong> as your branch to add a new policy if you haven't
                already done so.
            </p>
            <SmallGreyText>
                * Prices include the insurance premium, plus Insurance Premium Tax of 12% (where applicable) and an
                administration fee of £30. Cover is for a specified car that is already insured by the owner, up to
                insurance group 32 and £30k maximum value. Indicative quote based on a non-electric vehicle and less
                than 20 years old.
            </SmallGreyText>
        </Fragment>
    );
}

export default Caveats;
