import glamorous from 'glamorous';
import { atLeast } from '@marmalade/themes';

const ExtraPanelWrapper = glamorous('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [atLeast(theme.breakpoint_lg)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export default ExtraPanelWrapper;
