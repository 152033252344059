import React from 'react';
import Breadcrumbs from 'marketing/components/breadcrumbs';
import Watermark from 'marketing/components/watermark';
import { Container } from '@marmalade/grid';
import { withTheme, Div, P } from 'glamorous';
import { getWidth } from 'redux-window';
import { connect } from 'react-redux';
import { getBreakpoint } from 'modules/get-breakpoint';
import { number, object, bool, string, func } from 'prop-types';
import { LinkButton } from '@marmalade/button';
import { H1 } from 'components/typography';
import { showModal } from '@marmalade/modal/lib/actions';
import { Select } from '@marmalade/accesibility';

ProductHeroContent.propTypes = {
    width: number,
    theme: object,
    biba: bool,
    title: string,
    subtitle: string,
    showModal: func.isRequired,
};

const showModalOnClick = (showModal, modal) => () => {
    showModal(modal);
};

function ProductHeroContent({
    width,
    theme,
    biba = false,
    title = 'Set the title for this page',
    subtitle,
    showModal,
}) {
    switch (getBreakpoint(width, theme)) {
        case 'xs':
        case 'sm':
        case 'md':
        case 'lg':
            return (
                <Container>
                    <Breadcrumbs marginTop="1.4rem" />
                    <Div display="flex" flexDirection="column">
                        <H1 marginTop="3rem">{title}</H1>
                        {subtitle && (
                            <P marginTop="-1rem" fontSize="2.4rem">
                                {subtitle}
                            </P>
                        )}
                        <Div display="flex">
                            <Div width="21rem" marginRight="14rem" marginBottom="4rem">
                                <LinkButton block to="/register">
                                    Register Today!
                                </LinkButton>
                                <Div
                                    css={{
                                        '& a': {
                                            color: 'black',
                                            textDecoration: 'underline',
                                        },
                                    }}
                                >
                                    Already registered?{' '}
                                    <a
                                        onClick={showModalOnClick(showModal, 'login')}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={Select(showModalOnClick(showModal, 'login'))}
                                    >
                                        Login
                                    </a>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Container>
            );
        default:
            return (
                <Container>
                    <Breadcrumbs marginTop="1.4rem" />
                    <Div display="flex" flexDirection="column" width="62rem">
                        <H1 marginTop="3rem">{title}</H1>
                        {subtitle && (
                            <P marginTop="-1rem" fontSize="2.4rem">
                                {subtitle}
                            </P>
                        )}
                        <Div display="flex">
                            <Div width="21rem" marginRight="14rem" marginBottom={!!subtitle ? '4rem' : '15rem'}>
                                <LinkButton block to="/register">
                                    Register Today!
                                </LinkButton>
                                <Div
                                    fontSize="1.4rem"
                                    css={{
                                        '& a': {
                                            color: 'black',
                                            textDecoration: 'underline',
                                        },
                                    }}
                                >
                                    Already registered?{' '}
                                    <a
                                        onClick={showModalOnClick(showModal, 'login')}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={Select(showModalOnClick(showModal, 'login'))}
                                    >
                                        Login
                                    </a>
                                </Div>
                            </Div>
                            <Div marginTop="-6.5rem" marginBottom="6.5rem">
                                {biba && <Watermark />}
                            </Div>
                        </Div>
                    </Div>
                </Container>
            );
    }
}

export default withTheme(
    connect(
        state => ({
            width: getWidth(state),
        }),
        { showModal }
    )(ProductHeroContent)
);
