import AccountRoutes from '@marmalade/account/lib/components/account-routes';
import PrivateRoute from '@marmalade/account/lib/components/private-route';
import { paths as accountPaths } from '@marmalade/account/lib/constants';
import { fetchIsInternal } from '@marmalade/is-internal';
import Modal from '@marmalade/modal/lib/components/modal';
import ModalAnchor from '@marmalade/modal/lib/components/modal-anchor';
import ModalOverlay from '@marmalade/modal/lib/components/modal-overlay';
import ParseQuery from '@marmalade/query';
import ScrollToTop from '@marmalade/scroll-to-top';
import LogoutPage from 'admin/modules/logout';
import PAYGModal from 'admin/modules/quick-quotes/named/payg-modal';
import CallbackModal from 'admin/modules/quick-quotes/proceed-modal/modal';
import Account from 'admin/router';
import accountURLS from 'admin/urls';
import LoginModal from 'components/login-modal';
import MarketingRouter from 'marketing/router';
import DataLayer from 'modules/datalayer';
import { SitemapProvider } from 'modules/meta';
import { func } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { sitemap } from './constants';

class Router extends Component {
    static propTypes = {
        fetchIsInternal: func.isRequired,
    };

    componentDidMount() {
        this.props.fetchIsInternal();
    }

    render() {
        return (
            <BrowserRouter>
                <ParseQuery>
                    <ScrollToTop>
                        <SitemapProvider sitemap={sitemap}>
                            <DataLayer />
                            <ModalAnchor>
                                <Switch>
                                    <Route exact path="/logout" component={LogoutPage} />
                                    <AccountRoutes path={accountPaths} product="broker" accountHomeLink="/account" />
                                    <PrivateRoute path={accountURLS.account} component={Account} />
                                    <Route path="/" component={MarketingRouter} />
                                </Switch>
                            </ModalAnchor>
                            <ModalOverlay />
                            <Modal
                                key="modal"
                                content={[
                                    {
                                        Component: LoginModal,
                                        id: 'login',
                                    },
                                    {
                                        Component: CallbackModal,
                                        id: 'callback',
                                    },
                                    {
                                        Component: PAYGModal,
                                        id: 'payg',
                                    },
                                ]}
                            />
                        </SitemapProvider>
                    </ScrollToTop>
                </ParseQuery>
            </BrowserRouter>
        );
    }
}

export default connect(undefined, { fetchIsInternal })(Router);
