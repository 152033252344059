import React, { Fragment } from 'react';
import { object, string, bool } from 'prop-types';
import Icon from '@marmalade/icon';
import { atLeast } from '@marmalade/themes';
import isDesktop from '../../../is-desktop';

export const menuItemWrapperStyles = ({ theme }) => ({
    padding: `10px ${theme.space_xs}`,
    color: theme.colorWhite,
    borderColor: theme.colorGrey,
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
    fontSize: '14px',
    textAlign: 'center',
    ':hover': {
        color: theme.colorWhite,
        textDecoration: 'none',
        backgroundColor: theme.colorGreyHover,
    },
    [atLeast(theme.breakpoint_lg)]: {
        fontSize: '16px',
        textAlign: 'inherit',
        padding: `10px ${theme.space_sm}`,
    },
});

MenuItem.propTypes = {
    theme: object.isRequired,
    isDesktop: bool.isRequired,
    icon: string.isRequired,
    text: string.isRequired,
    shortText: string,
};

MenuItem.defaultProps = {
    shortText: undefined,
};

function MenuItem({ theme, icon, text, shortText, isDesktop }) {
    return (
        <Fragment>
            <Icon
                type={icon}
                css={{
                    fontSize: '18px',
                    display: 'block',
                    margin: '0 auto',
                    [atLeast(theme.breakpoint_lg)]: {
                        display: 'inline',
                        margin: '0 10px 0 0',
                    },
                }}
            />
            {!isDesktop && typeof shortText !== 'undefined' ? shortText : text}
        </Fragment>
    );
}

export default isDesktop(MenuItem);
