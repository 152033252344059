// This function will take a sitemap and the current path and return all information for the current route
export default function routeInformation(pathname, map, path = []) {
    for (let i = 0; i < map.length; i++) {
        if (map[i].url === pathname) {
            return [...path, map[i]];
        } else if (pathname.startsWith(map[i].url) || pathname === map[i].url) {
            if (!map[i].children) {
                return [...path, map[i]];
            }
            return routeInformation(pathname, map[i].children, [...path, { ...map[i], children: undefined }]);
        }
    }
    return path;
}
