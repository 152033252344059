import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getGuid } from '@marmalade/account/lib/selectors';

const stateToProps = state => {
    return {
        dataLayer: {
            userId: getGuid(state),
        },
    };
};

class DataLayer extends Component {
    componentDidMount() {
        // simulates a page land
        this.fireDataLayer();
    }

    shouldComponentUpdate(nextProps) {
        // Check for login or logout
        return nextProps.dataLayer.userId !== this.props.dataLayer.userId;
    }

    componentDidUpdate() {
        this.fireDataLayer();
    }

    fireDataLayer() {
        if (window.dataLayer) {
            window.dataLayer.push(this.props.dataLayer);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(connect(stateToProps)(DataLayer));
