import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import glamorous from 'glamorous';
import { H3 } from '@marmalade/bling/lib/headers/default';
import { LargePrimaryIcon } from '@marmalade/icon';

const BlockWrapper = glamorous('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
}));
const Block = glamorous('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.space_md,
    width: '50%',
    alignItems: 'center',
}));
const Text = glamorous('div')(({ theme }) => ({
    fontWeight: theme.fontBold,
}));
const Icon = glamorous(LargePrimaryIcon)(({ theme }) => ({
    marginRight: theme.space_md,
}));

WhyChoose.propTypes = {
    product: string.isRequired,
    reasons: arrayOf(shape({ text: string.isRequired, icon: string.isRequired })),
};

function WhyChoose({ product, reasons }) {
    return (
        <div>
            <H3>Why choose {product}?</H3>
            <BlockWrapper>
                {reasons.map(reason => (
                    <Block key={reason.text}>
                        <Icon type={reason.icon} />
                        <Text>{reason.text}</Text>
                    </Block>
                ))}
            </BlockWrapper>
        </div>
    );
}

export default WhyChoose;
