import { updateUser } from '@marmalade/account/lib/actions';
import { tobaEndpoint } from '../constants';

const NAME = 'confirmToba';
const T = {
    CONFIRM_TOBA: `${NAME}/CONFIRM_TOBA`,
};

export const confirmToba = () => dispatch => {
    dispatch({
        auth: true,
        type: T.CONFIRM_TOBA,
        product: 'broker',
        request: {
            baseUrl: tobaEndpoint + '/accept',
            method: 'post',
        },
        then: dispatch(updateUser({ acceptedLatestTerms: true })),
    });
};
