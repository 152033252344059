import React from 'react';
import Hero from 'marketing/components/hero';
import Lead from './lead';
import ProductHeroContent from './product-hero-content';
import { Head } from 'modules/meta';
import RegisterCTA from './register-cta';
import YoungDriverReviews from './young-driver-reviews';
import CustomerTestimonials from 'marketing/components/customer-testimonials';
import { connect } from 'react-redux';
import { withTheme } from 'glamorous';
import { string, arrayOf, bool, shape, node } from 'prop-types';
import { isTablet } from 'selectors';
import Layout from 'marketing/components/layout';

const backgroundColorForSection = i => (i % 2 === 0 ? '#fff' : '#f9f9f9');

Section.propTypes = {
    type: string.isRequired,
};

function Section({ type, ...props }, i) {
    const backgroundColor = backgroundColorForSection(i);
    switch (type) {
        case 'lead':
            return <Lead key={i} {...props} backgroundColor={backgroundColor} />;
        case 'register-cta':
            return <RegisterCTA key={i} {...props} backgroundColor={backgroundColor} />;
        case 'young-driver-reviews':
            return <YoungDriverReviews key={i} {...props} backgroundColor={backgroundColor} />;
        case 'customer-testimonials':
            return <CustomerTestimonials key={i} {...props} backgroundColor={backgroundColor} />;
        default:
            return null;
    }
}

ProductPage.propTypes = {
    image: string,
    sections: arrayOf(shape({ type: string })),
    caveats: arrayOf(node),
    tablet: bool.isRequired,
};

function ProductPage({ image, sections = [], caveats, tablet, ...heroContentProps }) {
    return (
        <Layout>
            <Head />
            <Hero
                background={
                    tablet
                        ? 'linear-gradient(#f5bb5e, #f5763e)'
                        : `url(${image}) no-repeat right center, linear-gradient(#f5bb5e, #f5763e)`
                }
                backgroundSize="auto 100%"
                color={backgroundColorForSection(0)}
                angle={2.6}
            >
                <ProductHeroContent {...heroContentProps} />
            </Hero>
            {sections.map(Section)}
        </Layout>
    );
}

export default withTheme(
    connect((state, { theme }) => ({
        tablet: isTablet(theme)(state),
    }))(ProductPage)
);
