import React, { Component } from 'react';
import YouTubeAPI from './api';
import Box from 'components/box';
import { string } from 'prop-types';
import { Div } from 'glamorous';

class Player extends Component {
    static propTypes = {
        videoId: string,
    };
    componentDidMount() {
        YouTubeAPI.ready(() => {
            this.player = new YouTubeAPI.Player(this.ref, {
                height: '100%',
                width: '100%',
                videoId: this.props.videoId,
            });
        });
    }
    register = ref => {
        this.ref = ref;
    };
    render() {
        return (
            <Div width="100%">
                <Box aspectRatio={16 / 9}>
                    <div ref={this.register}>Loading...</div>
                </Box>
            </Div>
        );
    }
}

export default Player;
