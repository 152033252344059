import React from 'react';
import { between } from '@marmalade/themes';
import glamorous, { H2, P } from 'glamorous';
import Icon from '@marmalade/icon';
import { string, node } from 'prop-types';

const featureWrapperStyles = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '5rem',
    maxWidth: '30%',
    [between(0, theme.breakpoint_md)]: {
        maxWidth: '100%',
    },
    '& svg': {
        color: theme.colorPrimary,
    },
});

const FeatureWrapper = glamorous.div(featureWrapperStyles);

const dividerStyles = ({ theme }) => ({
    background: 'linear-gradient(to right, #f5763e, #f5bb5e)',
    height: '0.3rem',
    width: '100%',
    marginBottom: '1.5rem',
    [between(0, theme.breakpoint_md)]: {
        height: '0',
        marginBottom: '0',
    },
});

const Divider = glamorous.div(dividerStyles);

Feature.propTypes = {
    title: string.isRequired,
    body: node.isRequired,
    icon: string.isRequired,
};

function Feature({ title, subtitle, body, icon }) {
    return (
        <FeatureWrapper>
            <Icon fontSize="5.4rem" type={icon} />
            <H2 fontSize="2rem" marginTop="1.5rem" marginBottom={subtitle ? '0.5rem' : '4.62rem'}>
                {title}
            </H2>
            {subtitle && <P color="#5c6770">{subtitle}</P>}
            <Divider />
            <P color="#5c6770" marginBottom="0">
                {body}
            </P>
        </FeatureWrapper>
    );
}

export default Feature;
