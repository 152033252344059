import React, { Fragment } from 'react';
import { Div } from 'glamorous';
import Radio from '@marmalade/form/lib/components/radio';
import Input from '@marmalade/form/lib/components/input';
import AddressLookup from '@marmalade/form/lib/components/address-lookup';
import Select from '@marmalade/form/lib/components/select';

function BrokerDetails() {
    return (
        <Fragment>
            <Radio label="Title" name="title" options={['Mr', 'Miss', 'Mrs', 'Ms']} />
            <Input label="First name" name="firstName" autoComplete="given-name" />
            <Input label="Last name" name="lastName" autoComplete="family-name" />
            <Input label="Company name" name="companyName" autoComplete="organization" />
            <Div maxWidth="42rem">
                <AddressLookup />
            </Div>
            <Div maxWidth="25.8rem">
                <Input label="Phone" name="work" autoComplete="tel" />
                <Input label="Mobile" name="mobile" autoComplete="tel" />
            </Div>
            <Input type="email" label="Email" name="email" autoComplete="email" />
            <Input type="email" label="Confirm email" name="confirmEmail" autoComplete="email" />
            <Select
                label="Are you a member of a network?"
                name="network"
                placeholder="Please select..."
                options={[
                    'No',
                    'Allianz',
                    'Applied Systems Insurecom',
                    'BIBA Scheme Member',
                    'Blink',
                    'Compass Network ',
                    'Countrywide',
                    'Independent Introducer',
                    'Marmalade',
                    'Ten Insurance Services',
                    'The Broker Network',
                    'UKGI',
                    'Other',
                ]}
            />
        </Fragment>
    );
}

export default BrokerDetails;
