import React from 'react';
import glamorous, { Polygon } from 'glamorous';
import { string } from 'prop-types';

const heroMaskStyles = () => ({
    position: 'absolute',
    bottom: -0.4,
    height: '100%',
});

const MaskContainer = glamorous('svg')(heroMaskStyles);

Mask.propTypes = {
    color: string,
};

function Mask({ color }) {
    return (
        <MaskContainer preserveAspectRatio="none" viewBox="0 0 100 100" width="100%">
            <Polygon points="0,0 100,100 0,100" fill={color} />
        </MaskContainer>
    );
}

export default Mask;
