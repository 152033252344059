import React from 'react';
import withModal from '@marmalade/modal/lib/components/with-modal';
import required from '@marmalade/validation/lib/required';
import Form from '@marmalade/form/lib/components/form';
import Input from '@marmalade/form/lib/components/input';
import Button from '@marmalade/button';
import glamorous, { H2, Div } from 'glamorous';
import { Link } from 'react-router-dom';
import Icon from '@marmalade/icon';
import { hideModal } from '@marmalade/modal/lib/actions';
import { connect } from 'react-redux';
import { func, bool, string } from 'prop-types';
import Message from '@marmalade/message';
import { login } from '@marmalade/account/lib/actions';
import { isLoggingIn, loginError } from '@marmalade/account/lib/selectors';
import Password from '@marmalade/form/lib/questions/password';

const CloseIcon = glamorous(Icon)(({ theme }) => ({
    color: theme.colorPrimary,
    fontSize: '2.6rem',
}));

LoginModal.propTypes = {
    loginError: string.isRequired,
    hideModal: func.isRequired,
    login: func.isRequired,
    posting: bool.isRequired,
};

const handleLogin = (action, hideModal) => ({ values }) => {
    action('broker', values.username, values.password);
};

function LoginModal({ hideModal, login, posting, loginError }) {
    return (
        <Div marginLeft="auto" marginRight="auto" backgroundColor="white" maxWidth="192rem">
            <Div marginLeft="auto" marginRight="auto" maxWidth="50rem" padding="1rem">
                <Div
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingTop="3.8rem"
                    paddingBottom="3.6rem"
                >
                    <H2 fontSize="3rem" margin={0}>
                        Marmalade broker portal
                    </H2>
                    <CloseIcon type="cross" onClick={hideModal} />
                </Div>
                <Form
                    id="login"
                    handleSubmit={handleLogin(login)}
                    validator={{
                        username: required('Please enter your username'),
                        password: required('Please enter your password'),
                    }}
                >
                    {loginError ? <Message type="warning">{loginError}</Message> : null}
                    <Input label="Username" name="username" />
                    <Password extra={<Link to="/reset-password">Forgotten password?</Link>} />
                    <Div display="flex" justifyContent="flex-end">
                        <Div width="18.4rem" marginBottom="5rem">
                            <Button block prominence="primary" type="submit" posting={posting}>
                                Login
                            </Button>
                        </Div>
                    </Div>
                </Form>
            </Div>
        </Div>
    );
}

export default withModal(
    connect(
        state => ({
            posting: isLoggingIn(state, 'login'),
            loginError: loginError(state),
        }),
        { hideModal, login }
    )(LoginModal)
);
