import React, { Component } from 'react';
import glamorous from 'glamorous';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { history } from '@marmalade/prop-types';
import { getQuoteInfo, hasQuoted } from '@marmalade/multi-quote/lib/quick-quote/selectors';
import Message from '@marmalade/message';
import Wrapper from 'admin/components/page-wrapper';
import H1 from '../../../components/h1';
import urls from '../../../urls';
import Lead from '../components/lead';
import CTABlock from '../components/quote/cta-block';
import Notification from '../components/quote/notification';
import ExtraPanelWrapper from '../components/quote/extra-panel-wrapper';
import Form from './form';
import reset from './reset';

const Hero = glamorous('div')(({ theme }) => ({
    textAlign: 'center',
}));

const MultiLead = glamorous(Lead)(({ theme }) => ({
    color: theme.colorGrey,
}));

class MultiGetAQuote extends Component {
    static propTypes = {
        quoted: bool.isRequired,
        reset: func.isRequired,
        history: history.isRequired,
        error: string.isRequired,
    };

    componentDidMount() {
        if (this.props.quoted) {
            this.props.reset();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.quoted && nextProps.quoted) {
            this.props.history.push(urls.multi + '/your-quotes');
        }
    }
    render() {
        return (
            <Wrapper>
                <Hero>
                    <H1>Get a multi quote</H1>

                    <MultiLead>
                        With just a few details, offer your customers a guide price on the Marmalade products most
                        suited to them – whether they will be using the family car, or insuring their own vehicle.
                    </MultiLead>
                </Hero>
                <Wrapper css={{ margin: '0 auto', maxWidth: '500px' }}>
                    {!!this.props.error.length && <Message type="error">{this.props.error}</Message>}
                    <Form />
                </Wrapper>
                <ExtraPanelWrapper>
                    <CTABlock
                        title="Marketing support"
                        text="Don't forget to take a look at our Marketing area for promotional materials from online adverts, to posters and leaflets!"
                        cta={{
                            text: 'View now',
                            link: '/account/leaflets',
                        }}
                    />
                    <Notification />
                </ExtraPanelWrapper>
            </Wrapper>
        );
    }
}

export default connect(
    state => {
        let error = '';
        const quote = getQuoteInfo(state);
        if (quote.requestError) {
            error = 'Sorry, there was an error fetching quote prices.';
        } else if (quote.quoteError) {
            error = 'Sorry, we are not able to provide a quote for the requested details.';
        }
        return { error, quoted: hasQuoted(state) };
    },
    { reset }
)(MultiGetAQuote);
