import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DropZone from 'react-dropzone';
import { object, func, bool, string } from 'prop-types';
import { withTheme } from 'glamorous';
import Loading from '@marmalade/loading';
import Button from '@marmalade/button';
import { getError } from '@marmalade/form/lib/selectors/field-info';
import Label from '@marmalade/form/lib/components/label';
import Error from '@marmalade/form/lib/components/error';
import Icon from '@marmalade/icon';
import { logoState, actions } from './save-logo';

class UploadLogo extends Component {
    static propTypes = {
        theme: object.isRequired,
        uploading: bool.isRequired,
        uploadError: bool.isRequired,
        fileName: string,
        fieldError: string,
        clearLogo: func.isRequired,
        saveLogo: func.isRequired,
    };

    static defaultProps = {
        fieldError: undefined,
        fileName: undefined,
    };

    uploadRef = null;
    setUploadRef = element => {
        this.uploadRef = element;
    };

    toggleUpload = () => {
        this.uploadRef.open();
    };

    upload = accepted => {
        if (accepted.length) {
            const formData = new FormData();
            formData.append('logo', accepted[0]);
            this.props.saveLogo(formData, accepted[0].name);
        }
    };

    error = () => {
        let error;
        if (this.props.fieldError) {
            error = this.props.fieldError;
        } else if (this.props.uploadError) {
            error = "Sorry, we only support PNG, GIF or JPEG's";
        }
        return error;
    };

    renderInput = () => {
        const { theme } = this.props;
        return (
            <Fragment>
                <DropZone
                    accept="image/*"
                    ref={this.setUploadRef}
                    onDrop={this.upload}
                    name="logo"
                    style={{
                        width: '100%',
                        height: 100,
                        marginBottom: theme.space_xs,
                        backgroundColor: theme.colorWhite,
                        border: `2px dashed ${theme.colorSecondary}`,
                        color: theme.colorSecondary,
                        textAlign: 'center',
                        lineHeight: '100px',
                    }}
                >
                    <Icon type="download" css={{ fontSize: '20px', marginRight: theme.space_sm }} />
                    Drag and drop to upload (PNG, JPEG or GIF)
                </DropZone>
                <Button
                    prominence="secondary"
                    text="Upload file"
                    style={{ marginBottom: theme.space_md }}
                    onClick={this.toggleUpload}
                />
            </Fragment>
        );
    };

    renderLoading = () => <Loading />;

    renderResult = () => {
        const { theme, clearLogo, fileName } = this.props;
        return (
            <p>
                {fileName}
                <a
                    onClick={clearLogo}
                    onKeyPress={clearLogo}
                    role="button"
                    tabIndex="0"
                    style={{
                        color: theme.colorPrimary,
                        marginLeft: theme.space_sm,
                    }}
                >
                    x remove
                </a>
            </p>
        );
    };

    renderConditional = () => {
        if (this.props.uploading) {
            return this.renderLoading();
        }
        if (this.props.fileName) {
            return this.renderResult();
        }
        return this.renderInput();
    };

    render() {
        return (
            <Fragment>
                <Label name="logo">Your logo (high resolution)</Label>
                {this.renderConditional()}
                {this.error() ? <Error>{this.error()}</Error> : null}
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        uploadError: logoState(state).error,
        uploading: logoState(state).uploading,
        fileName: logoState(state).fileName,
        fieldError: getError(state, 'posters', 'fileName'),
    }),
    {
        saveLogo: actions.saveLogo,
        clearLogo: actions.clearLogo,
    }
)(withTheme(UploadLogo));
