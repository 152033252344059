import React from 'react';
import { func } from 'prop-types';

BrokerAge.propTypes = {
    Age: func.isRequired,
};

function BrokerAge({ Age, ...rest }) {
    return <Age label="What is the young drivers age?" {...rest} />;
}

export default BrokerAge;
