import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, string, bool } from 'prop-types';
import { vehicleLookupValidator } from '@marmalade/ldi/lib/validation/shared/vehicle-questions';
import Form from '@marmalade/form/lib/components/form';
import FormNavigation from '@marmalade/form/lib/components/form-navigation';
import { handleQuickQuoteSubmit } from '@marmalade/ldi/lib/quote/actions';
import { getValue } from '@marmalade/form/lib/selectors/field-info';
import Age from '@marmalade/ldi/lib/new-business/quick-quote/age';
import { quoting } from '@marmalade/ldi/lib/quote/selectors';
import validator from '@marmalade/ldi/lib/validation/quick-quote';
import VehicleLookup from '../components/quote/vehicle-lookup';
import Postcode from '../components/quote/postcode';
import BrokerAge from '../components/quote/age';
import withGUID from '../../../with-guid';

export const formId = 'ldiQuickQuote';

class LDIForm extends Component {
    static propTypes = {
        handleQuickQuoteSubmit: func.isRequired,
        guid: string.isRequired,
        showForm: bool,
        posting: bool.isRequired,
    };

    static defaultProps = {
        showForm: false,
    };

    render() {
        return (
            <Form
                id={formId}
                handleSubmit={this.props.handleQuickQuoteSubmit(this.props.guid)}
                product="ldi"
                validator={{ ...validator, ...vehicleLookupValidator }}
            >
                <VehicleLookup />
                <Postcode disabled={!this.props.showForm} />
                <BrokerAge disabled={!this.props.showForm} Age={Age} extra={undefined} />
                <FormNavigation
                    next={{
                        submit: true,
                        text: 'Get a quote',
                        icon: false,
                        disabled: !this.props.showForm,
                        posting: this.props.posting,
                    }}
                />
            </Form>
        );
    }
}

export default withGUID(
    connect(
        state => ({
            showForm: !!getValue(state, formId, 'abiCode'),
            posting: quoting(state),
        }),
        {
            handleQuickQuoteSubmit,
        }
    )(LDIForm)
);
