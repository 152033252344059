import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Results from './results';
import GetAQuote from './get-a-quote';
import urls from '../../../urls';

const results = urls.ndiResults;

class NewDriver extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path={results} component={Results} />
                    <Route component={GetAQuote} />
                </Switch>
            </div>
        );
    }
}

export default NewDriver;
