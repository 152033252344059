import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { setValue } from '@marmalade/form/lib/actions/update-fields';

export const NAME = 'saveLogo';

const actionTypes = {
    SAVE_LOGO: `${NAME}/SAVE_LOGO`,
    CLEAR_LOGO: `${NAME}/CLEAR_LOGO`,
    NOTIFY: `${NAME}/NOTIFY`,
};

export const logoState = state => state[NAME];

export const actions = {
    saveLogo: (params, name) => dispatch => {
        dispatch({
            type: actionTypes.SAVE_LOGO,
            auth: true,
            product: 'broker',
            request: {
                method: 'post',
                baseUrl: `${process.env.REACT_APP_API_BASE_URI}/broker/v1/logo/upload`,
                params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                then: response => {
                    dispatch(setValue('posters', 'fileName', response.data.data));
                    return response;
                },
            },
            meta: { name },
        });
    },
    clearLogo: () => dispatch => {
        dispatch({ type: actionTypes.CLEAR_LOGO });
        dispatch(setValue('posters', 'fileName', ''));
    },
    notify: params => ({
        type: actionTypes.NOTIFY,
        auth: true,
        product: 'broker',
        request: {
            method: 'post',
            baseUrl: `${process.env.REACT_APP_API_BASE_URI}/broker/v1/logo/notify`,
            params,
        },
    }),
};

const initialState = {
    uploading: false,
    error: false,
    notified: false,
    notifyError: false,
    notifying: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case `${actionTypes.SAVE_LOGO}_${PENDING}`:
            return {
                ...state,
                uploading: true,
            };
        case `${actionTypes.SAVE_LOGO}_${FULFILLED}`:
            return {
                ...state,
                error: false,
                uploading: false,
                file: action.payload.data.data,
                fileName: action.meta.name,
            };
        case `${actionTypes.SAVE_LOGO}_${REJECTED}`:
            return {
                ...state,
                error: true,
            };
        case `${actionTypes.NOTIFY}_${PENDING}`:
            return {
                ...state,
                notifying: true,
            };
        case `${actionTypes.NOTIFY}_${FULFILLED}`:
            return {
                ...state,
                notifyError: false,
                notified: true,
                notifying: false,
            };
        case `${actionTypes.NOTIFY}_${REJECTED}`:
            return {
                ...state,
                notifyError: true,
                notified: false,
                notifying: false,
            };
        case actionTypes.CLEAR_LOGO: {
            return initialState;
        }
        default:
            return state;
    }
};
