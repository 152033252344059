import React from 'react';
import glamorous, { withTheme } from 'glamorous';
import { object } from 'prop-types';
import urls from '../../../urls';
import MenuGroup from './menu-group';
import MenuItem from './menu-nav-link';
import MenuTelLink from './menu-a-link';
import MenuHeader from './menu-header';

const MenuWrapper = glamorous('nav')(({ theme }) => ({
    backgroundColor: theme.colorBlack,
    flexGrow: 1,
}));

Menu.propTypes = {
    theme: object.isRequired,
};

function Menu({ theme }) {
    return (
        <MenuWrapper>
            <MenuGroup>
                <MenuItem to={urls.multi} icon="small-timer" css={{ borderTopWidth: '1px' }} text="Multi quote" />
                {/* <MenuItem to={urls.renewals} icon="small-renewals" text="Renewals" /> */}
                <MenuItem to={urls.notifications} icon="small-speech-bubble" text="Notifications" />
            </MenuGroup>
            <MenuGroup>
                <MenuHeader>Products</MenuHeader>
                <MenuItem to={urls.ldi} icon="small-l-plate" text="Learner Driver Insurance" shortText="Learner" />
                <MenuItem
                    to={urls.fdi}
                    icon="small-steering-wheel"
                    text="Named Young Driver Insurance"
                    shortText="Named"
                />
                <MenuItem to={urls.ndi} icon="small-p-plate" text="Black Box Insurance" shortText="Black Box" />
            </MenuGroup>
            <MenuGroup>
                <MenuHeader>Marketing</MenuHeader>
                <MenuItem to={urls.leaflets} icon="small-leaflet" text="Leaflets" />
                <MenuItem to={urls.logos} icon="small-logo" text="Logos and online adverts" shortText="Logos" />
                <MenuItem to={urls.posters} icon="small-poster" text="Posters" />
                <MenuItem to={urls.emails} icon="small-envelope" text="Emails" />
            </MenuGroup>
            <MenuGroup>
                <MenuHeader>Contact</MenuHeader>
                <MenuItem to={urls.contact} icon="small-envelope" text="Get in touch" />
                <MenuTelLink
                    href={'tel:' + theme.telephone}
                    icon="small-telephone"
                    text={theme.telephone}
                    shortText="Phone us"
                />
            </MenuGroup>
        </MenuWrapper>
    );
}

export default withTheme(Menu);
