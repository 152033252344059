import { assignEmail } from '@marmalade/account/lib/actions';
import { actionTypes as T } from './constants';

export default email => {
    return async (dispatch, getState) => {
        if (window.confirm(`Your new email will be ${email}. Please click OK to confirm.`)) {
            return dispatch({
                auth: true,
                type: T.SET_EMAIL,
                product: 'broker',
                request: {
                    baseUrl: `${process.env.REACT_APP_API_BASE_URI}/broker/v1/my-profile`,
                    params: { email },
                    method: 'post',
                    then: response => {
                        if (response.data.status === 'SUCCESS') {
                            dispatch(assignEmail());
                        }
                        return response;
                    },
                },
            });
        }
        return;
    };
};
