import List from '@marmalade/bling/lib/list';
import format from '@marmalade/date/lib/json-to-date';
import React, { Fragment } from 'react';

const documents = [
    {
        title: 'February Broker Update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1DR3W-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 7,
            month: 2,
            year: 2022,
        },
    },
    {
        title: 'January Broker Update (4/4) ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1DR3V-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 24,
            month: 1,
            year: 2022,
        },
    },
    {
        title: 'January Broker Update (3/4) ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1DR3U-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 17,
            month: 1,
            year: 2022,
        },
    },
    {
        title: 'January Broker Update (2/4) ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1DR3S-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 10,
            month: 1,
            year: 2022,
        },
    },
    {
        title: 'January Broker Update (1/4) ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1DR3R-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 4,
            month: 1,
            year: 2022,
        },
    },
    {
        title: 'November Broker Update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1DZTQ-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 11,
            year: 2021,
        },
    },
    {
        title: 'September Broker Update (2/2) ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1C9IC-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 9,
            year: 2021,
        },
    },
    {
        title: 'September Broker Update (1/2) ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1C5I9-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 9,
            year: 2021,
        },
    },
    {
        title: 'July Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1C1MG-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 7,
            year: 2021,
        },
    },
    {
        title: 'April/May Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-19Y4W-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 4,
            year: 2021,
        },
    },
    {
        title: 'January/February Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-18L9B-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 1,
            year: 2021,
        },
    },
    {
        title: 'November Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-17R11-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 11,
            year: 2020,
        },
    },
    {
        title: 'October Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-171BZ-EFB9F54ED227041180GT04EB953E6E28931BD/cr.aspx',
        date: {
            day: 1,
            month: 10,
            year: 2020,
        },
    },
    {
        title: 'September Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-1AWIC-8BC4399493BE9B895LVV5O0FA4379A1B011769/cr.aspx',
        date: {
            day: 1,
            month: 9,
            year: 2020,
        },
    },
    {
        title: 'June Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-15FQ9-7380GT044/cr.aspx',
        date: {
            day: 26,
            month: 6,
            year: 2020,
        },
    },
    {
        title: 'April Broker update ',
        url: 'https://wearemarmalade-news.co.uk/t/3LQ9-14J0G-7380GT044/cr.aspx',
        date: {
            day: 29,
            month: 4,
            year: 2020,
        },
    },
    {
        title: 'March Broker update ',
        url: 'https://wearemarmalade-news.co.uk/3LQ9-1451U-32/sv.aspx',
        date: {
            day: 26,
            month: 3,
            year: 2020,
        },
    },
    {
        title: 'February Broker update ',
        url: 'https://wearemarmalade-news.co.uk/3LQ9-13NAZ-32/sv.aspx',
        date: {
            day: 28,
            month: 2,
            year: 2020,
        },
    },
    {
        title: 'Christmas wishes from Marmalade',
        url: 'https://wearemarmalade-news.co.uk/3LQ9-12BJF-32/sv.aspx',
        date: {
            day: 13,
            month: 12,
            year: 2019,
        },
    },
    {
        title: 'Discounted Student Driver Insurance',
        url: 'https://wearemarmalade-news.co.uk/3LQ9-ZDIC-32/sv.aspx',
        date: {
            day: 19,
            month: 11,
            year: 2019,
        },
    },
    {
        title: 'Research around dangerous driving around learner drivers',
        url: 'https://wearemarmalade-news.co.uk/3LQ9-V9CF-32/sv.aspx',
        date: {
            day: 16,
            month: 10,
            year: 2019,
        },
    },
    {
        title: 'Important update on Young Driver Insurance products',
        url: 'https://wearemarmalade-news.co.uk/3LQ9-LB8Z-32/sv.aspx',
        date: {
            day: 1,
            month: 7,
            year: 2019,
        },
    },
    {
        title: 'Last chance to get your entries in to WIN!',
        url: process.env.PUBLIC_URL + '/newsletters/2019-last-chance-to-win.html',
        date: {
            day: 8,
            month: 5,
            year: 2019,
        },
    },
    {
        title: "Don't forget our exclusive competition for BIBA members",
        url: process.env.PUBLIC_URL + '/newsletters/2019-biba-competition.html',
        date: {
            day: 25,
            month: 4,
            year: 2019,
        },
    },
    {
        title: 'BIBA 2019 Less than a month to go!',
        url: process.env.PUBLIC_URL + '/newsletters/2019-biba-less-than-a-month.html',
        date: {
            day: 17,
            month: 4,
            year: 2019,
        },
    },
    {
        title: 'Easter customer discount for Student Driver Insurance',
        url: process.env.PUBLIC_URL + '/newsletters/2019-easter-discount-sdi.html',
        date: {
            day: 11,
            month: 4,
            year: 2019,
        },
    },
    {
        title: 'Broker competition reminder',
        url: process.env.PUBLIC_URL + '/newsletters/2019-broker-competition-reminder.html',
        date: {
            day: 4,
            month: 4,
            year: 2019,
        },
    },
    {
        title: 'Expand your offering for parents and young drivers',
        url: process.env.PUBLIC_URL + '/newsletters/2019-expand-offering.html',
        date: {
            day: 28,
            month: 3,
            year: 2019,
        },
    },
    {
        title: 'Student Driver Insurance for the Easter break',
        url: process.env.PUBLIC_URL + '/newsletters/2019-sdi-easter.html',
        date: {
            day: 21,
            month: 3,
            year: 2019,
        },
    },
    {
        title: 'Car sharing solutions for parents of young drivers',
        url: process.env.PUBLIC_URL + '/newsletters/2019-car-sharing-solutions.html',
        date: {
            day: 14,
            month: 3,
            year: 2019,
        },
    },
    {
        title: 'Marmalade multi quote',
        url: process.env.PUBLIC_URL + '/newsletters/2019-multiquote.html',
        date: {
            day: 7,
            month: 3,
            year: 2019,
        },
    },
    {
        title: 'WIN big with our exclusive broker competition',
        url: process.env.PUBLIC_URL + '/newsletters/2019-0103-brokercompetition.html',
        date: {
            day: 1,
            month: 3,
            year: 2019,
        },
    },
    {
        title: 'Named Young Driver Insurance promotion',
        url: process.env.PUBLIC_URL + '/newsletters/2018-nydi-promotion',
        date: {
            day: 18,
            month: 7,
            year: 2018,
        },
    },
    {
        title: 'Black Box Insurance car age update',
        url: process.env.PUBLIC_URL + '/newsletters/2018-ndi-update.html',
        date: {
            day: 26,
            month: 3,
            year: 2018,
        },
    },
    {
        title: 'BIBA Telematics Scheme',
        url: process.env.PUBLIC_URL + '/newsletters/2017-biba-telematics-scheme.html',
        date: {
            day: 14,
            month: 7,
            year: 2017,
        },
    },
    {
        title: 'New Telematics Products',
        url: process.env.PUBLIC_URL + '/newsletters/2017-new-telematics-products.html',
        date: {
            day: 4,
            month: 7,
            year: 2017,
        },
    },
    {
        title: 'Post BIBA 2017',
        url: process.env.PUBLIC_URL + '/newsletters/2017-post-biba.html',
        date: {
            day: 23,
            month: 5,
            year: 2017,
        },
    },
    {
        title: 'April Newsletter',
        url: process.env.PUBLIC_URL + '/newsletters/2017-april.html',
        date: {
            day: 11,
            month: 4,
            year: 2017,
        },
    },
    {
        title: 'December Newsletter',
        url: process.env.PUBLIC_URL + '/newsletters/2016-december.html',
        date: {
            day: 12,
            month: 12,
            year: 2016,
        },
    },
    {
        title: 'August Newsletter',
        url: process.env.PUBLIC_URL + '/newsletters/2016-august.html',
        date: {
            day: 1,
            month: 8,
            year: 2016,
        },
    },
    {
        title: 'May Newsletter',
        url: process.env.PUBLIC_URL + '/newsletters/2016-may.html',
        date: {
            day: 1,
            month: 6,
            year: 2016,
        },
    },
    {
        title: 'Post BIBA 2016',
        url: process.env.PUBLIC_URL + '/newsletters/2016-post-biba.html',
        date: {
            day: 24,
            month: 5,
            year: 2016,
        },
    },
    {
        title: 'January Newsletter',
        url: process.env.PUBLIC_URL + '/newsletters/2016-january.html',
        date: {
            day: 1,
            month: 1,
            year: 2016,
        },
    },
    {
        title: 'September Newsletter',
        url: process.env.PUBLIC_URL + '/newsletters/2015-september.html',
        date: {
            day: 1,
            month: 9,
            year: 2015,
        },
    },
];

function NotificationsList() {
    return (
        <List
            type="none"
            list={documents.map(document => (
                <Fragment>
                    <a href={document.url} target="_blank">
                        {document.title}
                    </a>{' '}
                    - {format(document.date)}
                </Fragment>
            ))}
        />
    );
}

export default NotificationsList;
