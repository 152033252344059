import { getGuid } from '@marmalade/account/lib/selectors';
import { H3 } from '@marmalade/bling/lib/headers/default';
import { LinkButton } from '@marmalade/button';
import { showModal } from '@marmalade/modal/lib/actions';
import { getPrices, getQuoteRefs, hasQuoted } from '@marmalade/multi-quote/lib/quick-quote/selectors';
import PageWrapper from 'admin/components/page-wrapper';
import withCreditBroker from 'admin/with-credit-broker';
import capitalize from 'lodash/capitalize';
import { bool, func, object, shape, string } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import isDesktop from '../../../../is-desktop';
import urls from '../../../../urls';
import CarDescription from '../../components/results/car-description';
import { priceProp } from '../../components/results/multi-day-prices';
import resultsWrapper from '../../components/results/results-wrapper';
import { TitleH2 as Title } from '../../components/results/title';
import { formId } from '../form';
import reset from '../reset';
import FDIResults from './fdi';
import LDIResults from './ldi';
import NDIResults from './ndi';

class MultiResults extends Component {
    static propTypes = {
        ldi: priceProp,
        ndi: object,
        fdi: object,
        isDesktop: bool.isRequired,
        showModal: func.isRequired,
        quoteRefs: shape({
            ldi: string,
            fdi: string,
            ndi: string,
        }).isRequired,
        ndiCreditBroker: bool.isRequired,
    };

    static defaultProps = {
        ldi: undefined,
        ndi: undefined,
        fdi: undefined,
    };

    openModal = product => () => {
        this.props.showModal('callback', undefined, {
            quoteRef: this.props.quoteRefs[product],
            product: 'multi' + capitalize(product),
        });
    };

    generatePanels() {
        return (
            <Fragment>
                {typeof this.props.fdi !== 'undefined' && (
                    <FDIResults
                        prices={this.props.fdi}
                        openModal={this.openModal('fdi')}
                        guid={this.props.guid}
                        quoteRef={this.props.quoteRefs.fdi.quickQuoteRef}
                    />
                )}
                {typeof this.props.ldi !== 'undefined' && <LDIResults prices={this.props.ldi} />}
                {typeof this.props.ndi !== 'undefined' && (
                    <NDIResults
                        prices={this.props.ndi}
                        openModal={this.openModal('ndi')}
                        quoteRef={this.props.quoteRefs.ndi}
                        creditBroker={this.props.ndiCreditBroker}
                        guid={this.props.guid}
                    />
                )}
            </Fragment>
        );
    }

    render() {
        return (
            <PageWrapper>
                <Title>Your customer's guide prices</Title>
                <CarDescription formId={formId} />
                {typeof this.props.fdi !== 'undefined' && (
                    <H3>
                        Choose from two great options that offer comprehensive cover with no risk to the car owner’s NCD
                    </H3>
                )}
                {this.generatePanels()}
                <LinkButton to={urls.multi}>Start a new quote</LinkButton>
            </PageWrapper>
        );
    }
}

export default withCreditBroker(
    isDesktop(
        connect(
            state => ({
                url: urls.multi,
                quoted: hasQuoted(state),
                quoteRefs: getQuoteRefs(state),
                guid: getGuid(state),
                ...getPrices(state),
            }),
            {
                showModal,
                reset,
            }
        )(resultsWrapper(MultiResults))
    )
);
