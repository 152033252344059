import { REJECTED, PENDING, FULFILLED } from 'redux-promise-middleware';
import { actionTypes as T } from './constants';

export default (
    state = {
        posting: false,
        error: '',
        complete: false,
    },
    action
) => {
    switch (action.type) {
        case `${T.SET_EMAIL}_${PENDING}`:
            return {
                posting: true,
                error: '',
                complete: false,
            };
        case `${T.SET_EMAIL}_${REJECTED}`:
            return {
                posting: false,
                error:
                    action.payload &&
                    action.payload.response &&
                    action.payload.response.data &&
                    action.payload.response.data.error
                        ? action.payload.response.data.error.description
                        : 'Sorry, an unknown error occurred, please try again.',
                complete: false,
            };
        case `${T.SET_EMAIL}_${FULFILLED}`:
            return {
                posting: false,
                error: '',
                complete: true,
            };
        default:
            return state;
    }
};
