import { getQuoteInfo, hasQuoted } from '@marmalade/ldi/lib/quote/selectors';
import { bool, func, string } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { VEHICLE_CAVEATS } from '../../../../constants';
import urls from '../../../urls';
import Page from '../components/quote/get-a-quote';
import Header from '../images/ldi-header.jpg';
import Documents from './documents';
import Form from './form';
import reset from './reset';

const results = urls.ldi + '/your-quote';

export const USPList = [
    'Learner drivers aged 17 -34',
    "No risk to owner's No Claims Discount",
    'Flexible insurance from 1 month to 8 months, with 7 & 14 days at renewal.',
    VEHICLE_CAVEATS,
    'Solutions for Learner Drivers looking to get insured on a parent’s or friend’s car',
];

LDIGetAQuote.propTypes = {
    error: string.isRequired,
    quoted: bool.isRequired,
    reset: func.isRequired,
};

function LDIGetAQuote({ error, quoted, reset }) {
    return (
        <Page
            quoted={quoted}
            reset={reset}
            results={results}
            error={error}
            title="Learner Driver Insurance"
            lead={
                <Fragment>
                    Flexible learner insurance to practise in a parent’s or friend’s car. You can earn up to{' '}
                    <strong>£50</strong> per new policy sold and up to <strong>£40</strong> per renewal.
                </Fragment>
            }
            image={Header}
            list={USPList}
            renewalMessage="Remember you can earn up to £15 per renewal with Learner Driver Insurance."
            product="ldi"
            documents={<Documents />}
            form={<Form />}
        />
    );
}

export default connect(
    state => {
        let error = '';
        if (getQuoteInfo(state).requestError) {
            error = 'Sorry, there was an error fetching quote prices.';
        } else if (getQuoteInfo(state).quoteError) {
            error = 'Sorry, we are not able to provide a quote for the requested details.';
        }
        return { quoted: hasQuoted(state), error };
    },
    { reset }
)(LDIGetAQuote);
