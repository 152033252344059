import React, { Component } from 'react';
import PageWrapper from 'admin/components/page-wrapper';

class Renewals extends Component {
    render() {
        return (
            <PageWrapper>
                <h1>Renewals</h1>
                <p>Under construction</p>
            </PageWrapper>
        );
    }
}

export default Renewals;
