import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import glamorous from 'glamorous';
import { getValue } from '@marmalade/form/lib/selectors/field-info';
import { vehicleDescription } from '@marmalade/form/lib/selectors/vehicle-lookup';
import Lead from '../lead';

const Description = glamorous(Lead)(({ theme }) => ({
    color: theme.colorGrey,
    marginBottom: theme.space_md,
}));

CarDescription.propTypes = {
    car: string.isRequired,
};

function CarDescription({ car }) {
    return <Description>{car}</Description>;
}

export default connect((state, ownProps) => {
    const reg = getValue(state, ownProps.formId, 'regNo');
    const description = vehicleDescription(state, ownProps.formId);
    return {
        car: `${typeof reg !== 'undefined' ? reg + ' ' : ''}${description}`,
    };
})(CarDescription);
