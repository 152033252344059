import { primaryIconComponent } from '@marmalade/icon';
import { hideModal } from '@marmalade/modal/lib/actions';
import glamorous, { withTheme } from 'glamorous';
import { func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const ModalWrapper = glamorous('div')({
    position: 'fixed',
    zIndex: 999999998,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'scroll',
});

const Modal = glamorous('div')(({ theme }) => ({
    backgroundColor: '#f9f9f9',
    maxWidth: '80vw',
    margin: '5% auto',
    borderRadius: theme.borderRadius,
    padding: '2rem 5rem',
    position: 'relative',
    textAlign: 'center',
}));

const Header = glamorous('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    paddingBottom: '1rem',
    borderBottom: `${theme.colorPrimary} 1px solid`,
}));

const Title = glamorous('strong')({
    textAlign: 'center',
    flexGrow: 1,
});

const CloseIcon = primaryIconComponent('16px');

const TopupTableWrapper = glamorous('div')(({ theme }) => ({
    display: 'grid',
    placeItems: 'center',
}));

const TopupTable = glamorous('table')(({ theme }) => ({
    marginBottom: '1em',
    backgroundColor: 'white',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    border: 'grey 1px solid',
    width: '100%',
}));

const TopupTableHeader = glamorous('th')(({ theme }) => ({
    backgroundColor: '#e6e6e6',
    padding: '0.5em',
    borderBottom: 'grey 1px solid',
}));

const TopupTableCell = glamorous('td')({
    padding: '0.5em',
    borderBottom: 'grey 1px solid',
    width: '33.3%',
});

class PAYGModal extends Component {
    static propTypes = {
        hideModal: func.isRequired,
        data: {
            quote: object.isRequired,
        }.isRequired,
    };

    static defaultProps = {};

    closeModal = () => {
        this.props.hideModal();
    };

    render() {
        return (
            <ModalWrapper>
                <Modal>
                    <Header>
                        <Title>Pay As You Go Pricing</Title>
                        <CloseIcon type="cross" onClick={this.closeModal} />
                    </Header>
                    <p>
                        The higher the driving score the cheaper the top-up price!
                        <br />
                        Top-ups are automatically taken when 50 miles are remaining
                    </p>
                    <TopupTableWrapper>
                        {Object.keys(this.props.data).map((mileage, index) => {
                            return (
                                <TopupTable key={index}>
                                    {index === 0 ? (
                                        <thead>
                                            <tr>
                                                <TopupTableHeader>Top up miles</TopupTableHeader>
                                                <TopupTableHeader>Driving score</TopupTableHeader>
                                                <TopupTableHeader>Top up price</TopupTableHeader>
                                            </tr>
                                        </thead>
                                    ) : null}
                                    <tbody>
                                        {Object.keys(this.props.data[mileage])
                                            .reverse()
                                            .map((drivingScore, scoreIndex) => {
                                                return (
                                                    <tr key={drivingScore}>
                                                        {scoreIndex === 0 ? (
                                                            <th rowSpan="4" style={{ borderRight: 'grey 1px solid' }}>
                                                                {mileage} miles
                                                            </th>
                                                        ) : null}
                                                        <TopupTableCell>{drivingScore}</TopupTableCell>
                                                        <TopupTableCell>
                                                            £{this.props.data[mileage][drivingScore].toFixed(2)}
                                                        </TopupTableCell>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </TopupTable>
                            );
                        })}
                    </TopupTableWrapper>
                </Modal>
            </ModalWrapper>
        );
    }
}

export default withRouter(withTheme(connect(() => {}, { hideModal })(PAYGModal)));
