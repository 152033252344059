import glamorous from 'glamorous';
import { Panel } from '@marmalade/panel';

export const PricePanel = glamorous(Panel)(({ theme, isDesktop }) => ({
    marginBottom: theme.space_sm,
    ...(isDesktop
        ? {
              display: 'flex',
              flexDirection: 'column',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
          }
        : {}),
}));

export const FeaturePanel = glamorous(Panel)(({ theme, isDesktop }) => ({
    backgroundColor: theme.colorOffWhiteDark,
    flex: '0 0 40%',
    marginBottom: theme.space_sm,
    ...(isDesktop
        ? {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeftWidth: 0,
          }
        : {}),
}));
