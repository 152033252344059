import FeatureLink from '@marmalade/bling/lib/feature-link';
import { H2, H3 } from '@marmalade/bling/lib/headers/default';
import { withTheme } from 'glamorous';
import fdiLeafletPdf from 'media/leaflets/fdi-leaflet.pdf';
import fdiLeaflet from 'media/leaflets/fdi-leaflet.png';
import ldiLeafletPdf from 'media/leaflets/ldi-leaflet.pdf';
import ldiLeaflet from 'media/leaflets/ldi-leaflet.png';
import ndiLeafletPdf from 'media/leaflets/ndi-leaflet.pdf';
import ndiLeaflet from 'media/leaflets/ndi-leaflet.png';
import { object } from 'prop-types';
import React from 'react';
import Text from '../../components/grey-paragraph';
import PageWrapper from '../../components/page-wrapper';

Leaflets.propTypes = {
    theme: object.isRequired,
};

function Leaflets({ theme }) {
    const featureLinkProps = {
        target: '_blank',
        css: {
            width: '33.3%',
            marginBottom: theme.space_lg,
            paddingRight: theme.space_xs,
            paddingLeft: theme.space_xs,
        },
        imgCss: { border: theme.border },
        iconType: 'document-pdf',
    };

    return (
        <PageWrapper>
            <H2>Leaflets</H2>
            <Text>
                You can download copies of our customer leaflets below or order new stock to be sent to you in the post.
                If you require more than 25 leaflets, please call us on <strong>{theme.telephone}</strong> to place your
                order.
            </Text>
            <H3>Download leaflets</H3>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexFlow: 'row wrap',
                    marginLeft: `-${theme.space_xs}`,
                    marginRight: `-${theme.space_xs}`,
                }}
            >
                <FeatureLink
                    title="Black Box"
                    imgSrc={ndiLeaflet}
                    alt="Black Box leaflet"
                    href={ndiLeafletPdf}
                    {...featureLinkProps}
                />
                <FeatureLink
                    title="Named Young Driver"
                    imgSrc={fdiLeaflet}
                    alt="Named Young Driver leaflet"
                    href={fdiLeafletPdf}
                    {...featureLinkProps}
                />
                <FeatureLink
                    title="Learner Driver"
                    imgSrc={ldiLeaflet}
                    alt="Learner Driver leaflet"
                    href={ldiLeafletPdf}
                    {...featureLinkProps}
                />
            </div>
        </PageWrapper>
    );
}

export default withTheme(Leaflets);
