import glamorous from 'glamorous';

const MenuHeader = glamorous('div')(({ theme }) => ({
    padding: `${theme.space_md} 0 ${theme.space_xs} ${theme.space_sm}`,
    textTransform: 'uppercase',
    color: theme.colorGrey,
    fontSize: '14px',
}));

export default MenuHeader;
