import { NAME } from './constants';

export const requestCallback = state => state[NAME];
export const submittingEmail = state => requestCallback(state).sendingEmail;
export const currentProduct = state => requestCallback(state).currentProduct;
export const submittingBrokerAction = state => requestCallback(state).sendingBrokerAction;
export const submitting = state => submittingEmail(state) || submittingBrokerAction(state);
export const error = state => requestCallback(state).error;
export const emailSent = state => requestCallback(state).emailSent;
export const actionSent = state => requestCallback(state).actionSent;
export const sentAll = state =>
    currentProduct(state) === 'fdi' ? actionSent(state) : emailSent(state) && actionSent(state);
