import { getQuotes, hasQuoted } from '@marmalade/ndi/lib/quick-quote/selectors';
import { bool, func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import urls from '../../../urls';
import Results from '../components/results';
import WhyChoose from '../components/results/why-choose';
import Documents from './documents';
import reset from './reset';
import ResultsPanel from './results-panel';

class NDIResults extends Component {
    static propTypes = {
        reset: func.isRequired,
        quoted: bool.isRequired,
        quotes: object
    };

    render() {
        const { quoted, quotes, reset } = this.props;
        return (
            <Results
                quoted={quoted}
                reset={reset}
                url={urls.ndi}
                documents={<Documents showPolicyBooklet underwriterId={quotes.underwriterId} />}
                panel={<ResultsPanel />}
                whyChoose={
                    <WhyChoose
                        product="Black Box Insurance"
                        reasons={[
                            { icon: 'clock', text: 'No curfews' },
                            {
                                icon: 'car-with-telematics',
                                text: 'Clever black box technology',
                            },
                            {
                                icon: 'phone-with-telematics',
                                text: 'Instantly review your journeys',
                            },
                            {
                                icon: 'pound-sign',
                                text: 'Earn your own No Claims Discount',
                            },
                        ]}
                    />
                }
            />
        );
    }
}

export default connect(
    state => ({
        quoted: hasQuoted(state),
        quotes: getQuotes(state)
    }),
    {
        reset,
    }
)(NDIResults);
