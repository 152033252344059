import React from 'react';
import { atLeast } from '@marmalade/themes';
import glamorous, { Q, Cite, Div } from 'glamorous';
import { string, number } from 'prop-types';

const slideStyles = ({ theme }) => ({
    padding: `0 ${theme.space_sm} ${theme.space_sm}`,
    display: 'flex',
    justifyContent: 'center',
    '& q': {
        color: theme.colorGrey,
    },
    [atLeast(theme.breakpoint_md)]: {
        padding: `0 ${theme.space_lg} ${theme.space_sm}`,
    },
});

const TestimonialRenderer = (slides, mobile) => {
    Slide.propTypes = {
        key: string,
        index: number,
    };
    function Slide({ key, index }) {
        const slideIndex = index % slides.length;
        const slide = slides[slideIndex < 0 ? Math.abs(slideIndex + slides.length) : slideIndex];
        const Slide = glamorous('div')(slideStyles);
        return (
            <Slide key={key}>
                <Div
                    textAlign="left"
                    maxWidth="75rem"
                    display="flex"
                    flexDirection={mobile ? 'column' : undefined}
                    alignItems={mobile ? 'center' : undefined}
                >
                    <Div width="132px" flexShrink={0} paddingRight="1.5rem">
                        <img src={slide.image} alt={slide.customer} height="132px" />
                    </Div>
                    <Div>
                        <Q
                            fontStyle="italic"
                            css={{
                                '&:before': { content: 'none' },
                                '&:after': { content: 'none' },
                            }}
                        >
                            {slide.testimonial}
                        </Q>
                        <br />
                        <Cite lineHeight="4rem" fontStyle="normal" fontWeight="700">
                            {slide.customer}
                        </Cite>
                    </Div>
                </Div>
            </Slide>
        );
    }
    return Slide;
};

export default TestimonialRenderer;
