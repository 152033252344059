import looksLikeEmail from '@marmalade/validation/lib/looks-like-email';
import mxLookup from '@marmalade/validation/lib/mx-lookup';
import oneByOne from '@marmalade/validation/lib/one-by-one';
import oneOf from '@marmalade/validation/lib/one-of';
import validateIf from '@marmalade/validation/lib/validate-if';
import name from '@marmalade/validation/lib/name';
import telephone from '@marmalade/validation/lib/telephone';
import validDate from '@marmalade/validation/lib/valid-date';
import notInPast from '@marmalade/validation/lib/not-in-past';

const validateIfCallbackTrue = validation => validateIf((_, values) => values.callback === 'Y', validation);

export default (family = false) => ({
    customerEmail: family ? () => '' : oneByOne(looksLikeEmail(), mxLookup()),
    callback: oneOf(['Y', 'N'], 'Please answer this question'),
    customerName: validateIfCallbackTrue(name("Please enter the customer's name")),
    customerPhoneNumber: validateIfCallbackTrue(telephone("Please enter the customer's telephone number")),
    dayToCall: validateIfCallbackTrue(oneByOne(validDate(), notInPast())),
    timeToCall: validateIfCallbackTrue(oneOf(['asap', 'am', 'pm'])),
});
