import glamorous from 'glamorous';
import { Panel } from '@marmalade/panel';
import { atLeast } from '@marmalade/themes';

const BorderlessPanel = Panel.withProps({ noBorder: true });

const ExtraPanel = glamorous(BorderlessPanel)(({ theme }) => ({
    padding: theme.space_md,
    [atLeast(theme.breakpoint_lg)]: {
        maxWidth: '48%',
        flex: '1 1 48%',
    },
}));

export default ExtraPanel;
