import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withTheme } from 'glamorous';
import Button, { ButtonGroup, LinkButton } from '@marmalade/button';
import { getUser } from '@marmalade/account/lib/selectors';
import PageNotFound from './modules/404';
import Notifications from './modules/notifications/notifications-page';
import Multi from './modules/quick-quotes/multi';
import Learner from './modules/quick-quotes/learner';
import NewDriver from './modules/quick-quotes/black-box';
import Leaflets from './modules/marketing/leaflets';
import Logos from './modules/marketing/logos';
import Posters from './modules/marketing/posters';
import Emails from './modules/marketing/emails';
import Renewals from './modules/renewals';
import Named from './modules/quick-quotes/named';
import Contact from './modules/contact';
import EmailMadness from './modules/email-madness';
import Layout from './components/layout';
import PageWrapper from './components/page-wrapper';
import URLS from './urls';
import { meta } from './meta';
import { Helmet } from 'react-helmet';
import { confirmToba } from './confirm-toba';
import { tobaEndpoint } from '../constants';

class Router extends Component {
    static propTypes = {
        user: object.isRequired,
        theme: object.isRequired,
        confirmToba: func.isRequired,
    };

    state = {
        tobaDownloaded: false,
    };

    downloadTOBA = () => {
        this.setState({ tobaDownloaded: true });
        window.open(tobaEndpoint);
    };

    render() {
        if (!this.props.user.acceptedLatestTerms) {
            return (
                <Layout>
                    <PageWrapper>
                        <p>Our Terms of Business have been updated. Please download and review.</p>
                        <ButtonGroup>
                            <Button text="Download TOBA" onClick={this.downloadTOBA} />
                            <Button
                                prominence="primary"
                                text="Accept new TOBA"
                                onClick={this.props.confirmToba}
                                disabled={!this.state.tobaDownloaded}
                            />
                        </ButtonGroup>
                    </PageWrapper>
                </Layout>
            );
        }
        if (this.props.user.requiresNewEmailAddress) {
            return (
                <Layout>
                    <PageWrapper>
                        <p>
                            Our new system requires all user accounts to have a unique email address. Please enter yours
                            below to continue using the portal.
                        </p>
                        <EmailMadness />
                    </PageWrapper>
                </Layout>
            );
        }
        if (!~this.props.user.scope.indexOf('agent')) {
            return (
                <Layout>
                    <div style={{ padding: this.props.theme.space_md }}>
                        <p>You are logged in as a branch. Please click below to log back in as an agent.</p>
                        <LinkButton prominence="primary" to="/login">
                            Log in
                        </LinkButton>
                    </div>
                </Layout>
            );
        }
        return (
            <Layout>
                <Switch>
                    {meta.routes.map(({ path, meta, exact = true }) => {
                        const render = () => <Helmet>{meta}</Helmet>;
                        return <Route key={path} exact={exact} path={path} render={render} />;
                    })}
                    <Route render={() => <Helmet>{meta.default}</Helmet>} />
                </Switch>
                <Switch>
                    <Redirect exact from={URLS.account} to={URLS.multi} />
                    <Route path={URLS.multi} component={Multi} />
                    <Route path={URLS.notifications} component={Notifications} />
                    <Route path={URLS.ldi} component={Learner} />
                    <Route path={URLS.ndi} component={NewDriver} />
                    <Route path={URLS.fdi} component={Named} />
                    <Route path={URLS.leaflets} component={Leaflets} />
                    <Route path={URLS.logos} component={Logos} />
                    <Route path={URLS.posters} component={Posters} />
                    <Route path={URLS.emails} component={Emails} />
                    <Route path={URLS.contact} component={Contact} />
                    <Route path={URLS.renewals} component={Renewals} />
                    <Route component={PageNotFound} />
                </Switch>
            </Layout>
        );
    }
}

export default connect(state => ({ user: getUser(state) }), { confirmToba })(withTheme(Router));
