import { Component } from 'react';
import { sitemap } from './prop-types';
import { node } from 'prop-types';

class SitemapProvider extends Component {
    static propTypes = {
        sitemap,
        children: node.isRequired,
    };
    static childContextTypes = {
        sitemap,
    };
    getChildContext() {
        return { sitemap: this.props.sitemap };
    }
    render() {
        return this.props.children || null;
    }
}

export default SitemapProvider;
