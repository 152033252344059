import { connect } from 'react-redux';
import { getWidth } from 'redux-window';
import { withTheme } from 'glamorous';

const isDesktop = Component =>
    withTheme(
        connect((state, ownProps) => ({
            isDesktop: getWidth(state) >= ownProps.theme.breakpoint_lg,
        }))(Component)
    );

export default isDesktop;
