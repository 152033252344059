import React from 'react';
import { connect } from 'react-redux';
import { string, object } from 'prop-types';
import glamorous, { withTheme } from 'glamorous';
import { getUsername } from '@marmalade/account/lib/selectors';

Welcome.propTypes = {
    name: string.isRequired,
    theme: object.isRequired,
};

function Welcome({ name, theme }) {
    return <glamorous.Div color={theme.colorGrey}>Welcome to Broker Portal {name}!</glamorous.Div>;
}

export default withTheme(connect(state => ({ name: getUsername(state) }))(Welcome));
