import React, { Component } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { hasQuoted } from '@marmalade/ldi/lib/quote/selectors';
import urls from '../../../urls';
import Results from '../components/results';
import WhyChoose from '../components/results/why-choose';
import Documents from './documents';
import ResultsPanel from './results-panel';
import reset from './reset';

class LDIResults extends Component {
    static propTypes = {
        reset: func.isRequired,
        quoted: bool.isRequired,
    };

    render() {
        const { quoted, reset } = this.props;
        return (
            <Results
                quoted={quoted}
                reset={reset}
                url={urls.ldi}
                documents={<Documents />}
                panel={<ResultsPanel />}
                whyChoose={
                    <WhyChoose
                        product="Learner Driver Insurance"
                        reasons={[
                            { icon: 'timer', text: 'Instant cover' },
                            {
                                icon: 'shield-with-lock',
                                text: "No risk to owner's No Claims Bonus",
                            },
                            {
                                icon: 'discounted',
                                text: '10% off Student Driver Insurance',
                            },
                            {
                                icon: 'calendar-sq',
                                text: 'Flexible cover',
                            },
                        ]}
                    />
                }
            />
        );
    }
}

export default connect(
    state => ({ quoted: hasQuoted(state) }),
    {
        reset,
    }
)(LDIResults);
