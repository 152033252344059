import React, { Fragment } from 'react';
import { string, number, shape, arrayOf, oneOfType } from 'prop-types';
import glamorous from 'glamorous';

const Wrapper = glamorous('div')(({ theme }) => ({
    marginBottom: theme.space_sm,
}));
const PriceWrapper = glamorous('div')(({ theme }) => ({
    marginBottom: theme.space_xs,
    fontWeight: theme.fontBold,
}));
const Day = glamorous('div')({
    verticalAlign: 'middle',
    width: '100px',
    display: 'inline-block',
});
const Price = glamorous('div')(({ theme, strikethrough }) => ({
    display: 'inline-block',
    width: '150px',
    fontSize: '30px',
    verticalAlign: 'middle',
    textDecoration: strikethrough ? 'line-through' : undefined,
}));

export const priceProp = arrayOf(shape({ day: string.isRequired, price: oneOfType([string, number]).isRequired }));

MultiDayPrices.propTypes = {
    prices: priceProp.isRequired,
};

function MultiDayPrices({ prices }) {
    return (
        <Wrapper>
            {prices
                .filter(({ day }) => day !== '365')
                .map(option => (
                    <PriceWrapper key={option.day}>
                        {<Day>{option.day} days:</Day>}
                        {typeof option.discounted !== 'undefined' && option.discounted !== null ? (
                            <Fragment>
                                {option.price ? <Price strikethrough>£{option.price}</Price> : null}
                                <Price>£{option.discounted}*</Price>
                            </Fragment>
                        ) : (
                            <Price>£{option.price}*</Price>
                        )}
                    </PriceWrapper>
                ))}
        </Wrapper>
    );
}

export default MultiDayPrices;
