import { H2 } from '@marmalade/bling/lib/headers/default';
import glamorous from 'glamorous';
import { string } from 'prop-types';
import React from 'react';

export const TitleH2 = glamorous(H2)(({ theme }) => ({
    marginBottom: theme.space_sm,
}));

Title.propTypes = {
    title: string,
};

function Title({ title = 'Your quote for comprehensive insurance for' }) {
    return <TitleH2>{title}</TitleH2>;
}

export default Title;
