// This function takes a site map and returns information for just the path your on
export default function pathInformation(pathname, map) {
    for (let i = 0; i < map.length; i++) {
        if (map[i].url === pathname) {
            return map[i];
        } else if (pathname.startsWith(map[i].url) || pathname === map[i].url) {
            return pathInformation(pathname, map[i].children);
        }
    }
    return { url: pathname, name: '404' };
}
