import { resetForm } from '@marmalade/form/lib/actions/update-form';
import { reset as resetModal } from './proceed-modal/submit-request';

const reset = (clearQuotes, mainFormId, proceedFormId) => () => dispatch => {
    dispatch(clearQuotes());
    dispatch(resetForm(mainFormId));
    dispatch(resetForm(proceedFormId));
    dispatch(resetModal());
};

export default reset;
