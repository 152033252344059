import React from 'react';
import { func, bool, object } from 'prop-types';
import { withRouter } from 'react-router';
import { withTheme } from 'glamorous';

import { history } from '@marmalade/prop-types';
import { Select } from '@marmalade/accesibility';
import Seperate from '@marmalade/headers/lib/base-components/seperate';
import BarWrapper from '@marmalade/headers/lib/base-components/standard-bar';
import MenuButton from '@marmalade/headers/lib/base-components/menu-button';
import ContactUs from '@marmalade/headers/lib/base-components/contact-us';

const push = (to, history) => e => {
    e.preventDefault();
    history.push(to);
};

BarComponent.propTypes = {
    onLogin: func,
    open: bool,
    toggle: func,
    history,
    tablet: bool,
    theme: object,
    mobile: bool,
};

function BarComponent({ onLogin, open, toggle, history, tablet, theme, mobile }) {
    if (mobile) {
        return (
            <BarWrapper
                logoProps={{
                    link: '/',
                    onClick: push('/', history),
                    small: true,
                }}
                noContainer
            >
                <MenuButton open={open} onClick={toggle} />
            </BarWrapper>
        );
    }
    if (tablet) {
        return (
            <BarWrapper logoProps={{ link: '/', onClick: push('/', history) }} noContainer>
                <MenuButton open={open} onClick={toggle} />
            </BarWrapper>
        );
    }

    return (
        <BarWrapper logoProps={{ link: '/', onClick: push('/', history) }}>
            <Seperate>
                {[
                    <ContactUs key="telephone" marginRight={theme.space_sm} margin={0} />,
                    ...(onLogin
                        ? [
                              <a onClick={onLogin} role="button" tabIndex={0} onKeyDown={Select(onLogin)} key="login">
                                  Login
                              </a>,
                          ]
                        : []),
                ]}
            </Seperate>
        </BarWrapper>
    );
}

const Bar = withTheme(withRouter(BarComponent));

export default ({ onLogin, tablet, width }) => (open, toggle) => (
    <Bar onLogin={onLogin} toggle={toggle} open={open} tablet={tablet} mobile={width < 450} />
);
