import React from 'react';
import urls from './urls';

const format = t => `${t} | Marmalade Broker`;

export const meta = {
    default: <title>{format('Page not found')}</title>,
    routes: [
        {
            path: urls.multi,
            meta: <title>{format('Multi Quote')}</title>,
        },
        {
            path: urls.multiResults,
            meta: <title>{format('Your Multi Quotes')}</title>,
        },
        {
            path: urls.notifications,
            meta: <title>{format('Notifications')}</title>,
        },
        {
            path: urls.ldi,
            meta: <title>{format('Learner driver insurance')}</title>,
        },
        {
            path: urls.ldiResults,
            meta: <title>{format('Your Learner driver insurance quote')}</title>,
        },
        {
            path: urls.fdi,
            meta: <title>{format('Named Young Driver Insurance')}</title>,
        },
        {
            path: urls.fdiResults,
            meta: <title>{format('Your Named Young Driver Insurance quote')}</title>,
        },
        {
            path: urls.ndi,
            meta: <title>{format('Black Box Insurance')}</title>,
        },
        {
            path: urls.ndiResults,
            meta: <title>{format('Your Black Box Insurance quote')}</title>,
        },
        {
            path: urls.leaflets,
            meta: <title>{format('Leaflets')}</title>,
        },
        {
            path: urls.logos,
            meta: <title>{format('Logos')}</title>,
        },
        {
            path: urls.posters,
            meta: <title>{format('Posters')}</title>,
        },
        {
            path: urls.emails,
            meta: <title>{format('Emails')}</title>,
        },
        {
            path: urls.contact,
            meta: <title>{format('Get in touch')}</title>,
        },
        {
            path: urls.renewals,
            meta: <title>{format('Earn again with renewals')}</title>,
        },
    ],
};
