import React from 'react';
import PageWrapper from '../../components/page-wrapper';
import Hero from './hero';
import Panel from './panel';

function ContactUs() {
    return (
        <div>
            <Hero />
            <PageWrapper>
                <Panel />
            </PageWrapper>
        </div>
    );
}

export default ContactUs;
