import componentThemes from '@marmalade/themes/lib/component-base-themes';

const { controlStyle } = componentThemes.form;

export const subTheme = {
    pageWidthLimit: 1920,
    logo_small_bp: 330,
    [controlStyle('backgroundColor')]: '#f9f9f9',
};

export default subTheme;
