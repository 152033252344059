const account = '/account';

const products = {
    ldi: account + '/learner-driver-insurance',
    ndi: account + '/black-box-insurance',
    fdi: account + '/named-young-driver-insurance',
    multi: account + '/multi-quote',
};

const result = '/your-quote';
const results = {
    multiResults: products.multi + '/your-quotes',
    ldiResults: products.ldi + result,
    ndiResults: products.ndi + result,
    fdiResults: products.fdi + result,
};

const urls = {
    ...products,
    ...results,
    notifications: account + '/notifications',
    leaflets: account + '/leaflets',
    logos: account + '/logos',
    posters: account + '/posters',
    emails: account + '/emails',
    renewals: account + '/renewals',
    contact: account + '/contact',
    account,
};

export default urls;
