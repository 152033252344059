import React from 'react';
import { object, bool } from 'prop-types';
import glamorous, { withTheme } from 'glamorous';
import Wrapper from '@marmalade/headers/lib/header-wrapper';
import HeaderContent from '@marmalade/headers/lib/base-components/header-content';
import ContactUs from '@marmalade/headers/lib/base-components/contact-us';
import Welcome from './welcome';
import Logo from './logo';
import Logout from './logout';

HeaderWrapper.propTypes = {
    theme: object.isRequired,
    logo: bool,
};

HeaderWrapper.defaultProps = {
    logo: false,
};

function HeaderWrapper(props) {
    const content = props.logo ? (
        <Logo />
    ) : (
        [
            <Welcome key="welcome" />,
            <glamorous.Div key="right" display="flex">
                <ContactUs color={props.theme.colorWhite} marginRight={props.theme.space_sm} />
                <Logout />
            </glamorous.Div>,
        ]
    );
    return (
        <Wrapper
            sections={[
                {
                    key: 'main',
                    render: () => (
                        <HeaderContent
                            css={{
                                backgroundColor: props.theme.colorBlack,
                            }}
                        >
                            {content}
                        </HeaderContent>
                    ),
                },
            ]}
        />
    );
}

export default withTheme(HeaderWrapper);
