/*
Hi Tim, imports should be listed in an order:
1st: 3rd party e.g. react
2nd: external but ours e.g. @marmalade/ldi
3rd: local e.g. './results
*/
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withTheme } from 'glamorous';
import { object } from 'prop-types';
import NewBusiness from '@marmalade/ldi/lib/new-business/routes';
import Results from './results';
import GetAQuote from './get-a-quote';
import urls from '../../../urls';

/*
Lianne has extracted the logic we didn't want in here, so you just need to add the LDI routes in below
Use the frsit route as the example
*/

class Learner extends Component {
    static propTypes = {
        theme: object.isRequired,
    };

    render() {
        return (
            <div>
                <Switch>
                    <Route
                        path={`${urls.ldi}/new`}
                        render={() => (
                            <div style={{ padding: this.props.theme.space_md }}>
                                <NewBusiness prefix={`${urls.ldi}/new`} />
                            </div>
                        )}
                    />
                    <Route path={urls.ldiResults} component={Results} />
                    <Route component={GetAQuote} />
                </Switch>
            </div>
        );
    }
}

export default withTheme(Learner);
