import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, string, bool } from 'prop-types';
import Form from '@marmalade/fdi/lib/quick-quote/components/form-shell';
import Age from '@marmalade/fdi/lib/quick-quote/components/age';
import FormNavigation from '@marmalade/form/lib/components/form-navigation';
import { resetFields } from '@marmalade/form/lib/actions/update-fields';
import { getValue } from '@marmalade/form/lib/selectors/field-info';
import VehicleLookup from '../components/quote/vehicle-lookup';
import LicenceHeld from '../components/quote/licence-held';
import Postcode from '../components/quote/postcode';
import BrokerAge from '../components/quote/age';
import withGUID from '../../../with-guid';
import BrokerTypeOfLicence from '../components/quote/type-of-licence';

export const formId = 'fdiQuickQuote';

class FDIForm extends Component {
    static propTypes = {
        resetFields: func.isRequired,
        showLicenceHeld: bool.isRequired,
        guid: string.isRequired,
        showForm: bool,
    };

    static defaultProps = {
        showForm: false,
    };

    resetLicenceHeldTime = () => {
        this.props.resetFields(formId, ['licenceHeld']);
    };

    render() {
        return (
            <Form formId={formId} guid={this.props.guid}>
                <VehicleLookup disableManualLookup />
                <Postcode disabled={!this.props.showForm} />
                <BrokerAge disabled={!this.props.showForm} Age={Age} />
                <BrokerTypeOfLicence disabled={!this.props.showForm} onChange={this.resetLicenceHeldTime} />
                {this.props.showLicenceHeld && <LicenceHeld />}
                <FormNavigation
                    next={{
                        submit: true,
                        text: 'Get a quote',
                        icon: false,
                        disabled: !this.props.showForm,
                    }}
                />
            </Form>
        );
    }
}

export default withGUID(
    connect(
        state => ({
            showForm: !!getValue(state, formId, 'abiCode'),
            showLicenceHeld: getValue(state, formId, 'licenceType') === 'F',
        }),
        {
            resetFields,
        }
    )(FDIForm)
);
