import React, { Component } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { hasQuoted } from '@marmalade/fdi/lib/quick-quote/selectors';
import urls from '../../../urls';
import Results from '../components/results';
import WhyChoose from '../components/results/why-choose';
import { reset } from '../proceed-modal/submit-request';
import Documents from './documents';
import ResultsPanel from './results-panel';

class FDIResults extends Component {
    static propTypes = {
        reset: func.isRequired,
        quoted: bool.isRequired,
    };

    render() {
        const { quoted, reset } = this.props;
        return (
            <Results
                quoted={quoted}
                reset={reset}
                url={urls.fdi}
                documents={<Documents />}
                panel={<ResultsPanel />}
                whyChoose={
                    <WhyChoose
                        product="Named Young Driver Insurance"
                        reasons={[
                            { icon: 'clock', text: 'No curfews' },
                            {
                                icon: 'car-with-telematics',
                                text: 'Clever black box technology',
                            },
                            {
                                icon: 'shield-with-lock',
                                text: "No risking parents' No Claims Bonus",
                            },
                            {
                                icon: 'pound-sign',
                                text: 'Earn your own No Claims Discount',
                            },
                        ]}
                    />
                }
            />
        );
    }
}

export default connect(
    state => ({ quoted: hasQuoted(state) }),
    {
        reset,
    }
)(FDIResults);
