import looksLikeEmail from '@marmalade/validation/lib/looks-like-email';
import mxLookup from '@marmalade/validation/lib/mx-lookup';
import oneByOne from '@marmalade/validation/lib/one-by-one';
import required from '@marmalade/validation/lib/required';
import isUrl from '@marmalade/validation/lib/is-url';
import isSortCode from '@marmalade/validation/lib/is-sort-code';
import isNumerical from '@marmalade/validation/lib/is-numerical';
import validateIf, { hasAValue } from '@marmalade/validation/lib/validate-if';
import telephone from '@marmalade/validation/lib/telephone';
import isBankAccountNumber from '@marmalade/validation/lib/is-bank-account-number';
import addressValidation from '@marmalade/validation/lib/bundles/address';

export default {
    title: required(),
    firstName: required(),
    lastName: required(),
    companyName: required(),
    work: oneByOne(required(), telephone()),
    mobile: oneByOne(required(), telephone()),
    email: oneByOne(required(), looksLikeEmail(), mxLookup()),
    confirmEmail: (value, values) => {
        if (value !== values.email) {
            return 'This must match the email you entered previously';
        }
        return '';
    },
    network: oneByOne(required(), value => (value === 'No' ? 'Please select a network' : '')),
    linkTo: required(),
    largerGroup: required(),
    fcaNumber: oneByOne(required(), isNumerical()),
    url: oneByOne(required(), isUrl()),
    bankName: () => '',
    sortCode: validateIf(hasAValue, isSortCode('Invalid sort code')),
    accountNumber: validateIf(hasAValue, isBankAccountNumber()),
    TOBA: value => {
        if (!value || value.length !== 1) {
            return 'You must confirm you have read the TOBA before prcoceeding.';
        }
        return '';
    },
    ...addressValidation(),
};
