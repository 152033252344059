import { getUser } from '@marmalade/account/lib/selectors';
import { getQuoteInfo, hasQuoted } from '@marmalade/ndi/lib/quick-quote/selectors';
import { bool, func, string } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import urls from '../../../urls';
import Page from '../components/quote/get-a-quote';
import Header from '../images/ndi-header.jpg';
import Documents from './documents';
import Form from './form';
import reset from './reset';

const results = urls.ndi + '/your-quote';

export const USPList = [
    'For full and provisional licence holders aged 17-30',
    'No curfews - drive 24/7',
    'Build No Claims Bonus straight away',
    'Covers cars up to 25 years old',
];

NDIGetAQuote.propTypes = {
    error: string.isRequired,
    quoted: bool.isRequired,
    reset: func.isRequired,
};

function NDIGetAQuote({ error, quoted, reset, user }) {
    return (
        <Page
            reset={reset}
            quoted={quoted}
            results={results}
            error={error}
            title="Black Box Insurance"
            lead={
                <Fragment>
                    Young drivers with their own car could save money and build up their own No Claims Discount, and you
                    can earn up to <strong>£75</strong> for each new policy!
                </Fragment>
            }
            image={Header}
            list={USPList}
            renewalMessage="Don't forget you can earn 7.5% of the premium or £50 (whichever is lower) for any New Driver renewals. "
            product="ndi"
            documents={<Documents showQuickQuoteGuide={!user.isNdiCreditBroker} />}
            form={<Form />}
        />
    );
}

export default connect(
    state => {
        let error = '';
        if (getQuoteInfo(state).requestError) {
            error = 'Sorry, there was an error fetching quote prices.';
        } else if (getQuoteInfo(state).quoteError) {
            error = 'Sorry, we are not able to provide a quote for the requested details.';
        }
        return { quoted: hasQuoted(state), error, user: getUser(state) };
    },
    { reset }
)(NDIGetAQuote);
