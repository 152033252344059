import React, { Component } from 'react';
import { Div } from 'glamorous';
import axios from 'axios';
import Button from '@marmalade/button';
import Form from '@marmalade/form/lib/components/form';
import Input from '@marmalade/form/lib/components/input';
import Textarea from '@marmalade/form/lib/components/textarea';
import required from '@marmalade/validation/lib/required';
import oneByOne from '@marmalade/validation/lib/one-by-one';
import looksLikeEmail from '@marmalade/validation/lib/looks-like-email';
import mxLookup from '@marmalade/validation/lib/mx-lookup';
import Message from '@marmalade/message';

const { REACT_APP_API_BASE_URI } = process.env;

class ContactForm extends Component {
    state = {
        submitted: false,
        error: false,
    };
    handleSubmit = ({ values, validAndPristine }) => {
        if (!validAndPristine) {
            return;
        }
        axios
            .post(`${REACT_APP_API_BASE_URI}/broker/v1/contact-us`, values)
            .then(() => {
                this.setState({ submitted: true });
            })
            .catch(() => {
                this.setState({ error: true });
            });
    };
    render() {
        if (this.state.submitted) {
            return <p>Thanks for getting in touch, a member of our broker team will be in touch shortly.</p>;
        }
        return (
            <Form
                id="contact"
                handleSubmit={this.handleSubmit}
                validator={{
                    name: required(),
                    email: oneByOne(required(), looksLikeEmail(), mxLookup()),
                    phone: required(),
                    message: required(),
                }}
            >
                {this.state.error && <Message type="error">Something went wrong! Please try again or call us.</Message>}
                <Div maxWidth="30rem">
                    <Input label="Name" name="name" />
                    <Input label="Email" name="email" />
                    <Input label="Phone" name="phone" />
                </Div>
                <Textarea name="message" label="Message" rows="11" cols="50" />
                <Div display="flex" justifyContent="flex-end">
                    <Div maxWidth="24rem" width="100%">
                        <Button prominence="primary" type="submit" marginLeft="auto" block>
                            Send message
                        </Button>
                    </Div>
                </Div>
            </Form>
        );
    }
}

export default ContactForm;
