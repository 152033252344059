import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '@marmalade/account/lib/actions';
import { Redirect } from 'react-router-dom';
import { func } from 'prop-types';

class Logout extends Component {
    static propTypes = {
        logout: func,
    };
    componentWillMount() {
        this.props.logout();
    }
    render() {
        return <Redirect to="/" />;
    }
}

export default connect(
    undefined,
    { logout }
)(Logout);
