import React from 'react';
import { node, object } from 'prop-types';
import glamorous, { withTheme, ThemeProvider } from 'glamorous';
import { atLeast } from '@marmalade/themes';
import Page from '@marmalade/page';
import Header from '../modules/navigation/header/wrapper';
import Menu from '../modules/navigation/menu/menu';
import brokerTheme from '../theme';
import Footer from './footer';

const contentSection = {
    display: 'flex',
    flexDirection: 'column',
};
// We have to use both Wrapper and Admin, rather than a single div to get around an IE11 bug.
// https://github.com/philipwalton/flexbugs#flexbug-3
const Wrapper = glamorous(Page)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: theme.colorWhite,
}));
const Admin = glamorous('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100vh',
}));
const Left = glamorous('div')(contentSection, ({ theme }) => ({
    width: '100px',
    flexShrink: 0,
    [atLeast(theme.breakpoint_lg)]: { width: '300px' },
}));
const Right = glamorous('div')(contentSection, {
    flex: '1 1 100%',
});

Layout.propTypes = {
    children: node.isRequired,
    theme: object.isRequired,
};

function Layout({ theme, children }) {
    return (
        <ThemeProvider theme={brokerTheme(theme)}>
            <Wrapper>
                <Admin>
                    <Left>
                        <Header logo />
                        <Menu>Menu</Menu>
                    </Left>
                    <Right>
                        <Header />
                        <glamorous.Div
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            flexGrow={1}
                        >
                            {children}
                            <Footer />
                        </glamorous.Div>
                    </Right>
                </Admin>
            </Wrapper>
        </ThemeProvider>
    );
}

export default withTheme(Layout);
