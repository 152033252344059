import React from 'react';
import { object, bool } from 'prop-types';
import glamorous from 'glamorous';
import { H3 } from '@marmalade/bling/lib/headers/default';
import A from '@marmalade/bling/lib/a';
import Icon from '@marmalade/icon';
import { constants } from '@marmalade/contact';
import isDesktop from '../../is-desktop';
import ContactList from './contact-list';

const Wrapper = glamorous('div')(({ theme, isDesktop }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    flexBasis: isDesktop ? '66%' : '100%',
}));
const Column = glamorous('div')(({ theme }) => ({
    flexBasis: '50%',
    paddingBottom: theme.space_md,
}));
const Title = glamorous(H3)(({ theme }) => ({ marginBottom: theme.space_xs }));
const SocialList = glamorous('ul')(({ theme }) => ({
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    fontSize: '40px',
}));
const SocialIcon = glamorous('li')(({ theme }) => ({
    marginRight: theme.space_xs,
}));

ContactDetails.propTypes = {
    theme: object.isRequired,
    isDesktop: bool.isRequired,
};

function ContactDetails({ theme, isDesktop }) {
    return (
        <Wrapper isDesktop={isDesktop}>
            <Column>
                <Title>
                    <Icon
                        type="pin"
                        css={{
                            color: theme.colorPrimary,
                            marginRight: theme.space_xs,
                        }}
                    />
                    Address
                </Title>
                {constants.address.map((line, i) => (
                    <div key={'line' + i}>{line}</div>
                ))}
            </Column>
            <Column>
                <ContactList />
            </Column>
            <Column>
                <Title>Follow Us</Title>
                <SocialList>
                    {[
                        {
                            icon: 'twitter',
                            link: 'https://twitter.com/' + constants.social.twitter,
                        },
                        {
                            icon: 'fb',
                            link: 'https://www.facebook.com/' + constants.social.facebook,
                        },
                        {
                            icon: 'youtube-play',
                            link: 'https://www.youtube.com/user/' + constants.social.youtube,
                        },
                        {
                            icon: 'linkedin',
                            link: 'https://www.linkedin.com/company/we-are-marmalade/',
                        },
                    ].map(link => (
                        <SocialIcon key={link.icon}>
                            <A
                                css={{
                                    color: theme.colorBlack,
                                    ':hover': { textDecoration: 'none' },
                                }}
                                href={link.link}
                            >
                                <Icon type={'circle-social-' + link.icon} />
                            </A>
                        </SocialIcon>
                    ))}
                </SocialList>
            </Column>
        </Wrapper>
    );
}

export default isDesktop(ContactDetails);
