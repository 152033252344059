import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { string, object, bool, func, shape, arrayOf, oneOf } from 'prop-types';
import glamorous, { withTheme, ThemeProvider } from 'glamorous';
import { componentThemes } from '@marmalade/themes';
import { primaryIconComponent } from '@marmalade/icon/';
import { setValues } from '@marmalade/form/lib/actions/update-fields';
import { getValue } from '@marmalade/form/lib/selectors/field-info';
import { isSubmittingForm } from '@marmalade/form/lib/selectors/form-info';
import { hideModal } from '@marmalade/modal/lib/actions';
import { submitForm, reset } from './submit-request';
import Form from './modal-form';
import Sent from './modal-sent';
import { submitting, sentAll } from './selectors';

const { form } = componentThemes;

// normally product will be ndi, etc. If currently on multiquote product will be multiNdi.
// We want the form id to just be multiProceed though to make sure they don't have to fill in the form multiple times to send quotes.

const isMulti = product => !!~product.indexOf('multi');

export const getFormId = product => (isMulti(product) ? 'multiProceed' : `${product}Proceed`);

const CloseIcon = glamorous(primaryIconComponent('16px'))(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.space_sm,
}));

const ModalWrapper = glamorous('div')({
    position: 'fixed',
    zIndex: 999999998,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'scroll',
});

const Modal = glamorous('div')(({ theme }) => ({
    backgroundColor: theme.colorWhite,
    maxWidth: '600px',
    margin: '5% auto',
    borderRadius: theme.borderRadius,
    padding: theme.space_md,
    position: 'relative',
}));

class ProceedModal extends Component {
    static propTypes = {
        data: shape({
            quoteRef: string.isRequired,
            product: oneOf(['fdi', 'ndi', 'ldi', 'multiFdi', 'multiNdi', 'multiLdi']).isRequired,
        }).isRequired,
        theme: object.isRequired,
        setValues: func.isRequired,
        reset: func.isRequired,
        hideModal: func.isRequired,
        submitForm: func.isRequired,
        posting: bool.isRequired,
        sentAll: bool.isRequired,
        callback: bool,
        dayToCall: arrayOf(string),
    };

    static defaultProps = {
        callback: true,
        dayToCall: ['', '', ''],
    };

    componentDidMount() {
        const today = new Date();
        this.props.setValues(getFormId(this.props.data.product), {
            quoteRef: this.props.data.quoteRef.quickQuoteRef
                ? this.props.data.quoteRef.quickQuoteRef
                : this.props.data.quoteRef,
            product: this.product,
            callback: 'Y',
            // If no day has been set - make it today, otherwise use existing.
            dayToCall: this.props.dayToCall.some(value => !value.length)
                ? [today.getDate().toString(), (today.getMonth() + 1).toString(), today.getFullYear().toString()]
                : this.props.dayToCall,
        });
    }

    // Normally the product will be ndi.
    // We drop the multi part so the form knows the true product.
    product = this.props.data.product.replace('multi', '').toLowerCase();

    closeModal = () => {
        // Fire reset so we always show the form on opening
        this.props.reset();
        this.props.hideModal();
    };

    render() {
        return (
            <ThemeProvider theme={form.offWhiteInputTheme(this.props.theme)}>
                <ModalWrapper>
                    <Modal>
                        <CloseIcon type="cross" onClick={this.closeModal} />
                        {this.props.sentAll ? (
                            <Sent
                                closeModal={this.closeModal}
                                product={this.product}
                                callback={this.props.callback}
                                multi={isMulti(this.props.data.product)}
                            />
                        ) : (
                            <Form
                                formId={getFormId(this.props.data.product)}
                                product={this.product}
                                handleSubmit={this.props.submitForm}
                                callbackRequired={this.props.callback}
                                posting={this.props.posting}
                            />
                        )}
                    </Modal>
                </ModalWrapper>
            </ThemeProvider>
        );
    }
}

export default withRouter(
    withTheme(
        connect(
            (state, ownProps) => ({
                callback: getValue(state, getFormId(ownProps.data.product), 'callback') === 'Y',
                dayToCall: getValue(state, getFormId(ownProps.data.product), 'dayToCall'),
                posting: isSubmittingForm(state, getFormId(ownProps.data.product)) || submitting(state),
                sentAll: sentAll(state),
            }),
            { setValues, hideModal, submitForm, reset }
        )(ProceedModal)
    )
);
