import React, { Component, Fragment } from 'react';
import { string, element, bool } from 'prop-types';
import glamorous from 'glamorous';
import { atLeast } from '@marmalade/themes';
import PageWrapper from 'admin/components/page-wrapper';
import isDesktop from '../../../../is-desktop';
import Breadcrumbs from './breadcrumbs';
import resultsWrapper from './results-wrapper';

const DocumentWrapper = glamorous('div')(({ theme }) => ({
    marginBottom: theme.space_lg,
    [atLeast(theme.breakpoint_lg)]: {
        width: '25%',
        flex: '0 0 auto',
    },
}));

const DesktopWrapper = glamorous('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const PanelColumn = glamorous('div')(({ theme }) => ({
    width: '71%',
    flex: '0 0 auto',
}));

class Results extends Component {
    static propTypes = {
        url: string.isRequired,
        panel: element.isRequired,
        documents: element.isRequired,
        whyChoose: element.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { panel, url, documents, whyChoose, isDesktop } = this.props;
        const docs = <DocumentWrapper>{documents}</DocumentWrapper>;
        return (
            <PageWrapper>
                <Breadcrumbs url={url} />
                {isDesktop ? (
                    <DesktopWrapper>
                        <PanelColumn>
                            {panel}
                            {whyChoose}
                        </PanelColumn>
                        {docs}
                    </DesktopWrapper>
                ) : (
                    <Fragment>
                        {panel}
                        {docs}
                        {whyChoose}
                    </Fragment>
                )}
            </PageWrapper>
        );
    }
}

export default isDesktop(resultsWrapper(Results));
