export const headerOptions = [
    {
        to: '/products',
        text: 'Products',
    },
    {
        to: '/about-us',
        text: 'About us',
    },
    {
        to: '/contact-us',
        text: 'Contact us',
    },
    {
        to: '/register',
        text: 'Register',
    },
];
