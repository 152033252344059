import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { func, bool, string } from 'prop-types';
import { product } from '@marmalade/prop-types';
import Form from '@marmalade/form/lib/components/form';
import Email from '@marmalade/form/lib/questions/email';
import Input from '@marmalade/form/lib/components/input';
import FormNavigation from '@marmalade/form/lib/components/form-navigation';
import DateInput from '@marmalade/form/lib/components/split-field/date';
import Radio from '@marmalade/form/lib/components/radio';
import RadioYesNo from '@marmalade/form/lib/components/radio/radio-yes-no';
import { H2 } from '@marmalade/bling/lib/headers/default';
import Message from '@marmalade/message';
import validator from './validation';
import { error } from './selectors';

class CallbackForm extends Component {
    static propTypes = {
        formId: string.isRequired,
        product: product.isRequired,
        handleSubmit: func.isRequired,
        callbackRequired: bool.isRequired,
        posting: bool.isRequired,
        error: string.isRequired,
    };

    render() {
        const { formId, product, handleSubmit, callbackRequired, posting, error } = this.props;
        return (
            <Fragment>
                <H2 css={{ textAlign: 'center' }}>Please enter customer's details</H2>
                <Form
                    id={formId}
                    product={product}
                    handleSubmit={handleSubmit}
                    validator={validator(product === 'fdi')}
                >
                    {!!error.length && <Message type="error">{error}</Message>}
                    <Email helpText="" label={'Customer email'} placeholder="" name="customerEmail" />
                    {<RadioYesNo label="Would you like to arrange a callback?" name="callback" />}

                    {callbackRequired && (
                        <Fragment>
                            <Input name="customerName" label="Customer name" />
                            <Input
                                name="customerPhoneNumber"
                                label="Customer phone number"
                                type="number"
                                format="int"
                            />
                            <DateInput label="Day to call" name="dayToCall" />
                            <Radio
                                label="Preferred time to call"
                                name="timeToCall"
                                options={[
                                    {
                                        text: 'As soon as possible',
                                        value: 'asap',
                                    },
                                    { text: 'Morning', value: 'am' },
                                    { text: 'Afternoon', value: 'pm' },
                                ]}
                            />
                        </Fragment>
                    )}
                    <FormNavigation
                        next={{
                            submit: true,
                            text: 'Send',
                            icon: false,
                            posting,
                        }}
                    />
                </Form>
            </Fragment>
        );
    }
}

export default connect(state => ({
    error: error(state) ? 'Sorry there was a problem submitting the form' : '',
}))(CallbackForm);
