import React from 'react';
import { string } from 'prop-types';
import glamorous, { withTheme } from 'glamorous';
import MenuItem, { menuItemWrapperStyles } from './menu-item';

const Link = glamorous('a')(menuItemWrapperStyles);

MenuALink.propTypes = {
    href: string.isRequired,
    alt: string,
    target: string,
};

MenuALink.defaultProps = {
    alt: undefined,
    target: undefined,
};

function MenuALink({ href, target, alt, ...rest }) {
    return (
        <Link href={href} target={target} alt={alt}>
            <MenuItem {...rest} />
        </Link>
    );
}

export default withTheme(MenuALink);
