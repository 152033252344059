import React, { Fragment } from 'react';
import glamorous from 'glamorous';
import SortCode from '@marmalade/form/lib/components/split-field/sort-code';
import Input from '@marmalade/form/lib/components/input';

const copyStyles = ({ theme }) => ({
    fontSize: '1.6rem',
    color: theme.colorGrey,
    marginBottom: '2rem',
});

const Copy = glamorous.div(copyStyles);

function BankingDetails() {
    return (
        <Fragment>
            <h3>Banking Details</h3>
            <p>
                Please provide the following so that we may <b>pay your commission directly into your account.</b> No
                money can be taken from your account.
            </p>
            <Copy>
                Security Note: This site is secure and can be verified by clicking on the Padlock displayed at the top.
                All data is encrypted and sent securely to our administrators enabling us to pay your commission as and
                when it is due. Sending details at a later stage by phone, or email, is much less secure, time consuming
                and prone to human error.
            </Copy>

            <Input label="Bank name (optional)" name="bankName" />
            <SortCode label="Sort code (optional)" name="sortCode" />
            <Input format="int" label="Account number (optional)" name="accountNumber" />
        </Fragment>
    );
}

export default BankingDetails;
