import React from 'react';
import { func, string, bool } from 'prop-types';
import { Redirect } from 'react-router-dom';

function resultsWrapper(Component) {
    return class ResultsWrapper extends Component {
        static propTypes = {
            reset: func.isRequired,
            url: string.isRequired,
            quoted: bool.isRequired,
        };

        componentWillUnmount() {
            this.props.reset();
        }

        render() {
            const { quoted, url, ...rest } = this.props;
            if (!quoted) {
                return <Redirect to={url} />;
            }
            return <Component url={url} {...rest} />;
        }
    };
}

export default resultsWrapper;
