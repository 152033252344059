import glamorous from 'glamorous';
import capitalize from 'lodash/capitalize';
import { string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Wrapper = glamorous('div')(({ theme }) => ({
    fontSize: '16px',
    marginBottom: theme.space_sm,
}));

Breadcrumbs.propTypes = {
    url: string.isRequired,
};

function Breadcrumbs({ url }) {
    return (
        <Wrapper>
            <Link to={url}>
                {url
                    .split('/')[2]
                    .split('-')
                    .map(word => capitalize(word))
                    .join(' ')}
            </Link>
            {' > '}
            Your Quote
        </Wrapper>
    );
}

export default Breadcrumbs;
