import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'glamorous';
import Home from 'marketing/pages/home';
import ProductsRouter from 'marketing/pages/products/router';
import AboutUs from 'marketing/pages/about-us';
import CookiePolicy from 'marketing/pages/cookie-policy';
import ContactUs from 'marketing/pages/contact-us';
import Register from 'marketing/pages/register';
import PageNotFound from 'marketing/pages/404';
import RegisterSuccess from 'marketing/pages/register-success';
import theme from './theme';
import { connect } from 'react-redux';
import { isLoggedIn } from '@marmalade/account/lib/selectors';
import { bool } from 'prop-types';

Router.propTypes = {
    isLoggedIn: bool.isRequired,
};

function Router({ isLoggedIn }) {
    if (isLoggedIn && window.location.pathname !== '/cookie-policy') {
        return <Redirect to="/account" />;
    }
    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/products" component={ProductsRouter} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/register-success" component={RegisterSuccess} />
                <Route exact path="/cookie-policy" component={CookiePolicy} />
                <Route component={PageNotFound} />
            </Switch>
        </ThemeProvider>
    );
}

export default withRouter(connect(state => ({ isLoggedIn: isLoggedIn(state) }))(Router));
