import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PageNotFound from 'marketing/pages/404';

import Products from './index';

import LDIPage from './ldi';
import NYDIPage from './nydi';
import NDIPage from './ndi';
function Router() {
    return (
        <Switch>
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/ldi" component={LDIPage} />
            <Route exact path="/products/nydi" component={NYDIPage} />
            <Route exact path="/products/ndi" component={NDIPage} />
            <Route component={PageNotFound} />
        </Switch>
    );
}

export default Router;
