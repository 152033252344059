import React from 'react';
import { Section } from 'glamorous';
import { Container } from '@marmalade/grid';
import Reviews from '@marmalade/trust-pilot/lib/reviews';
import { string } from 'prop-types';

YoungDriverReviews.propTypes = {
    backgroundColor: string,
};

function YoungDriverReviews({ backgroundColor }) {
    return (
        <Section backgroundColor={backgroundColor} paddingTop="9rem" paddingBottom="10rem">
            <Container>
                <Reviews />
            </Container>
        </Section>
    );
}

export default YoungDriverReviews;
