import glamorous from 'glamorous';
import { atLeast } from '@marmalade/themes';

const PageWrapper = glamorous('div')(({ theme }) => ({
    padding: theme.space_sm,
    [atLeast(theme.breakpoint_lg)]: {
        padding: theme.space_md,
    },
}));

export default PageWrapper;
