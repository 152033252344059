import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { actionTypes as T } from './constants';

const initialState = {
    sendingEmail: false,
    sendingBrokerAction: false,
    error: false,
    emailSent: false,
    actionSent: false,
    currentProduct: 'unknown',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case T.UPDATE_CURRENT_PRODUCT:
            return { ...state, currentProduct: action.payload };
        case `${T.SEND_BROKER_ACTION}_${PENDING}`:
            return { ...state, sendingBrokerAction: true };
        case `${T.SEND_BROKER_ACTION}_${FULFILLED}`:
            return { ...state, sendingBrokerAction: false, actionSent: true };
        case `${T.SEND_BROKER_ACTION}_${REJECTED}`:
            return { ...state, sendingBrokerAction: false, error: true };
        case `${T.SEND_EMAIL_QUOTE}_${PENDING}`:
            return { ...state, sendingEmail: true };
        case `${T.SEND_EMAIL_QUOTE}_${FULFILLED}`:
            return { ...state, sendingEmail: false, emailSent: true };
        case `${T.SEND_EMAIL_QUOTE}_${REJECTED}`:
            return { ...state, sendingEmail: false, error: true };
        case T.RESET:
            return initialState;
        default:
            return state;
    }
};
