import { getGuid } from '@marmalade/account/lib/selectors';
import Button, { ButtonGroup, LinkButton } from '@marmalade/button';
import domain from '@marmalade/domain';
import { showModal } from '@marmalade/modal/lib/actions';
import { getQuotes, quoteRef } from '@marmalade/ndi/lib/quick-quote/selectors';
import withCreditBroker from 'admin/with-credit-broker';
import glamorous from 'glamorous';
import { bool, func, number, shape, string } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import urls from '../../../urls';
import AnnualOrMonthlyPrices from '../components/results/annual-or-monthly-prices';
import ResultsPanel from '../components/results/results-panel';
import { AnnualCaveats } from './caveats';
import { formId } from './form';

const QuoteWrapper = glamorous('div')(({ theme }) => ({
    marginBottom: theme.space_sm,
}));

const NDIResultsPanel = ({ prices, quoteRef, showModal, ndiCreditBroker, guid }) => {
    return (
        <ResultsPanel formId={formId}>
            <QuoteWrapper>
                <AnnualOrMonthlyPrices {...prices} />
                <ButtonGroup>
                    {ndiCreditBroker ? (
                        <Button
                            Element="a"
                            href={`${domain('newdriver.wearemarmalade')}/broker/login?quoteref=${quoteRef}`}
                            target="_blank"
                            prominence="primary"
                        >
                            Proceed to full quote
                        </Button>
                    ) : (
                        <Fragment>
                            <Button
                                Element="a"
                                href={`${domain(
                                    'newdriver.wearemarmalade'
                                )}/new/stage1?quoteref=${quoteRef}&guid=${guid}`}
                                target="_blank"
                                prominence="primary"
                            >
                                Proceed to full quote
                            </Button>
                            <Button
                                text="Request call back"
                                prominence="primary"
                                onClick={() =>
                                    showModal('callback', undefined, {
                                        product: 'ndi',
                                        quoteRef,
                                    })
                                }
                            />
                        </Fragment>
                    )}
                    <LinkButton to={urls.ndi}>Get a new quote</LinkButton>
                </ButtonGroup>
            </QuoteWrapper>
            <AnnualCaveats />
        </ResultsPanel>
    );
};

NDIResultsPanel.propTypes = {
    guid: string.isRequired,
    prices: shape({
        monthly: string.isRequired,
        annual: string.isRequired,
        totalPayable: string.isRequired,
        apr: number.isRequired,
        months: number.isRequired,
        deposit: string.isRequired,
    }),
    showModal: func.isRequired,
    quoteRef: string.isRequired,
    ndiCreditBroker: bool.isRequired,
};

export default withCreditBroker(
    connect(
        state => ({
            guid: getGuid(state),
            prices: getQuotes(state),
            quoteRef: quoteRef(state),
        }),
        { showModal }
    )(NDIResultsPanel)
);
