import React from 'react';
import { object } from 'prop-types';
import { Img, withTheme } from 'glamorous';
import { atLeast } from '@marmalade/themes';

LogoHeader.propTypes = {
    theme: object.isRequired,
};

function LogoHeader({ theme }) {
    return (
        <Img
            src={theme.logo_small_white}
            css={{
                height: '50px',
                padding: '7px 0',
                display: 'block',
                maxWidth: 'inherit',
                margin: '0 auto',
                [atLeast(theme.breakpoint_lg)]: {
                    margin: `0 0 0 ${theme.space_sm}`,
                },
            }}
        />
    );
}

export default withTheme(LogoHeader);
