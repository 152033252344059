import HR from '@marmalade/bling/lib/hr';
import glamorous, { A, Div } from 'glamorous';
import React from 'react';
import PageWrapper from './page-wrapper';

const FooterWrapper = glamorous('footer')(({ theme }) => ({
    fontSize: '14px',
    color: theme.colorGrey,
}));

function Footer(props) {
    return (
        <FooterWrapper>
            <PageWrapper>
                <HR />
                <Div fontSize="1.4rem" marginBottom="1rem">
                    <A href="/cookie-policy" marginRight="1.4rem">
                        Cookie policy
                    </A>
                    {/* <A
                        onClick={() =>
                            window && typeof window.openCookiePrefs === 'function' && window.openCookiePrefs()
                        }
                        cursor="pointer"
                    >
                        Change cookie settings
                    </A> */}
                </Div>
                <p>
                    Marmalade is a trading name of Atlanta Insurance Intermediaries Limited. Authorised and Regulated by
                    the Financial Conduct Authority under firm reference number 309599. Company registration number:
                    756681. Registered address: Embankment West Tower, 101 Cathedral Approach, Salford, M3 7FB.
                    <br />
                    Atlanta Insurance Intermediaries Limited can be found on the Financial Services Register under
                    reference number 309599. For more information, please contact us.
                    <br />
                    Calls are recorded for training and quality purposes.
                </p>
            </PageWrapper>
        </FooterWrapper>
    );
}

export default Footer;
