import FeatureLink from '@marmalade/bling/lib/feature-link';
import { H2, H3 } from '@marmalade/bling/lib/headers/default';
import Text from 'admin/components/grey-paragraph';
import PageWrapper from 'admin/components/page-wrapper';
import { withTheme } from 'glamorous';
import ndiBanner from 'media/ads/ndi-banner.gif';
import ndiMpu from 'media/ads/ndi-mpu.gif';
import ndiLogo from 'media/logos/marmalade-bbi-logo.svg';
import ldiLogo from 'media/logos/marmalade-ldi-logo.svg';
import nydLogo from 'media/logos/marmalade-nyd-logo.svg';
import { bool, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isTablet } from 'selectors';
import BannerAd from './banner-ad';

Logos.propTypes = {
    theme: object.isRequired,
    isTablet: bool.isRequired,
};

function Logos({ theme, isTablet }) {
    const featureLinkProps = {
        target: '_blank',
        css: {
            width: isTablet ? '50%' : '33.3%',
            marginBottom: theme.space_md,
            paddingRight: theme.space_sm,
            paddingLeft: theme.space_sm,
        },
        imgCss: {
            maxWidth: 200,
        },
        iconType: 'download',
    };
    return (
        <PageWrapper>
            <H2>Logos and online adverts</H2>
            <Text>Download our logos to have on your website with your unique Marmalade website links.</Text>
            <H3>Download logos</H3>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexFlow: 'row wrap',
                    marginBottom: theme.space_md,
                    marginLeft: `-${theme.space_xs}`,
                    marginRight: `-${theme.space_xs}`,
                }}
            >
                <FeatureLink
                    title="Black Box"
                    imgSrc={ndiLogo}
                    alt="Black Box logo"
                    href={ndiLogo}
                    {...featureLinkProps}
                />
                <FeatureLink
                    title="Named Young Driver"
                    imgSrc={nydLogo}
                    alt="Named Young Driver logo"
                    href={nydLogo}
                    {...featureLinkProps}
                />
                <FeatureLink
                    title="Learner Driver"
                    imgSrc={ldiLogo}
                    alt="Learner Driver logo"
                    href={ldiLogo}
                    {...featureLinkProps}
                />
            </div>
            <H3>Digital adverts</H3>
            <Text>
                If you would like to use our display adverts for your website, please request them from the Broker Team
                by clicking one of the buttons below.
            </Text>
            <BannerAd
                title="Black Box banners"
                src={{
                    mpu: ndiMpu,
                    banner: ndiBanner,
                }}
                html={{
                    mpu: `<img alt="Marmalade get a Black Box Insurance quote!" src="https://broker.wearemarmalade.co.uk${ndiMpu}">`,
                    banner: `<img alt="Marmalade get a Black Box Insurance quote!" src="https://broker.wearemarmalade.co.uk${ndiBanner}">`,
                }}
            />
        </PageWrapper>
    );
}

export default withTheme(
    connect((state, ownProps) => ({
        isTablet: isTablet(ownProps.theme)(state),
    }))(Logos)
);
