import React, { Fragment } from 'react';
import { string, arrayOf, shape, bool } from 'prop-types';
import glamorous, { withTheme } from 'glamorous';
import { H3 } from '@marmalade/bling/lib/headers/default';
import List from '@marmalade/bling/lib/list';
import { primaryIconComponent } from '@marmalade/icon';
import Text from '../../../components/grey-paragraph';
import isDesktop from '../../../is-desktop';

export const documentsProps = arrayOf(shape({ text: string.isRequired, url: string.isRequired }).isRequired).isRequired;

const Icon = glamorous(primaryIconComponent('2.6rem'))(({ theme }) => ({
    marginRight: theme.space_sm,
    verticalAlign: 'middle',
}));

export const DocumentList = withTheme(DocumentListComponent);

DocumentListComponent.propTypes = {
    documents: documentsProps,
    stacked: bool,
};

DocumentListComponent.defaultProps = {
    stacked: false,
};

function DocumentListComponent({ documents, stacked }) {
    return (
        <List
            type="none"
            list={documents.map(document => (
                <a href={document.url}>
                    <Icon type="document-pdf" />
                    {document.text}
                </a>
            ))}
            liCss={{
                marginBottom: stacked ? '10px' : 0,
                display: stacked ? 'block' : 'inline-block',
                marginRight: stacked ? 0 : '35px',
            }}
            css={{ margin: 0 }}
        />
    );
}

Documents.propTypes = {
    text: string.isRequired,
    documents: documentsProps,
    isDesktop: bool.isRequired,
};

function Documents({ text, documents, isDesktop }) {
    return (
        <Fragment>
            <H3>Download documents</H3>
            <Text>{text}</Text>
            <DocumentList documents={documents} stacked={isDesktop} />
        </Fragment>
    );
}

export default isDesktop(Documents);
