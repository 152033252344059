import React, { Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { withTheme } from 'glamorous';
import { product } from '@marmalade/prop-types';
import { H2 } from '@marmalade/bling/lib/headers/default';
import Button, { LinkButton } from '@marmalade/button';
import urls from '../../../urls';
import PanelParagraph from '../../../components/grey-paragraph';

Sent.propTypes = {
    product: product.isRequired,
    theme: object.isRequired,
    closeModal: func.isRequired,
    callback: bool.isRequired,
    multi: bool.isRequired,
};

function Sent({ product, theme, closeModal, callback, multi }) {
    const dlEventName = `proceedQuote${product.toUpperCase()}`;
    window.dataLayer.push({ event: dlEventName });
    return (
        <Fragment>
            <H2>
                Thank you - we've sent out the quote
                {callback && ' and arranged for a callback'}
            </H2>
            <PanelParagraph>
                If you need any further support, please contact our broker team on {theme.telephone} or at{' '}
                <a href="mailto:broker@wearemarmalade.co.uk">broker@wearemarmalade.co.uk</a>
            </PanelParagraph>
            <Button prominence="primary" block onClick={closeModal}>
                Return to quote
            </Button>
            <LinkButton to={multi ? urls.multi : urls[product]} block>
                Get a new quote
            </LinkButton>
        </Fragment>
    );
}

export default withTheme(Sent);
