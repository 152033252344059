import React, { Fragment } from 'react';
import SmallGreyText from '../components/small-grey-text';

export function AnnualCaveats() {
    return (
        <Fragment>
            <SmallGreyText>
                This is not a formal quote. A full quote will be provided to the customer by a Marmalade advisor if the
                customer chooses to proceed.
            </SmallGreyText>
            <SmallGreyText>
                Unsafe driving can result in premium increases and ultimately policy cancellation but 9 out of 10
                Marmalade customers have not had that problem. Quote is based on a mileage of 7000 per annum and a
                voluntary excess payment of £250. Please note the price quoted indicative, based on the limited
                information provided, and is subject to change upon completion of a full quote.
            </SmallGreyText>
        </Fragment>
    );
}
