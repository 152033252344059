import React from 'react';
import { H2 } from '@marmalade/bling/lib/headers/default';
import PageWrapper from '../../components/page-wrapper';
import Text from '../../components/grey-paragraph';
import NotificationsList from './list';

function Notifications(props) {
    return (
        <PageWrapper>
            <H2>Notifications</H2>
            <Text>
                Keep up to date with the latest news and products with Marmalade. Please find below all our recent
                communications, in date order. Simply click on the title of the communication you wish to view or
                download.
            </Text>
            <NotificationsList />
        </PageWrapper>
    );
}

export default Notifications;
