import React from 'react';
import { bool } from 'prop-types';
import glamorous from 'glamorous';
import { H3 } from '@marmalade/bling/lib/headers/default';
import isDesktop from '../../is-desktop';
import coral from './images/coral.png';

const team = [{ name: 'Coral Nicholson', image: coral }];

const Wrapper = glamorous('div')(({ theme, isDesktop }) => ({
    flexBasis: isDesktop ? '30%' : '100%',
}));
const TeamWrapper = glamorous('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row wrap',
}));
const TeamMember = glamorous('div')(({ theme, isDesktop }) => ({
    flexBasis: isDesktop ? '100%' : '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: theme.fontBold,
    padding: `0 ${theme.space_sm} ${theme.space_sm} 0`,
}));
const TeamImage = glamorous('img')(({ theme }) => ({
    paddingRight: theme.space_sm,
}));

MeetTeam.propTypes = {
    isDesktop: bool.isRequired,
};

function MeetTeam({ isDesktop }) {
    return (
        <Wrapper isDesktop={isDesktop}>
            <H3>Meet the team</H3>
            <TeamWrapper>
                {team.map(member => (
                    <TeamMember key={member.name} isDesktop={isDesktop}>
                        <TeamImage src={member.image} />
                        {member.name}
                    </TeamMember>
                ))}
            </TeamWrapper>
        </Wrapper>
    );
}

export default isDesktop(MeetTeam);
