export const tobaEndpoint = `${process.env.REACT_APP_API_BASE_URI}/broker/v1/tob`;

export const sitemap = [
    {
        url: '/',
        name: 'Home',
        title: 'Insure young drivers and earn commission',
        changefreq: 'monthly',
        src: 'src/marketing/pages/home/index.js',
        priority: 1,
        children: [
            {
                url: '/products',
                name: 'Products',
                title: 'Insure young drivers with Marmalade and earn commissions of up to £250 per customer',
                changefreq: 'monthly',
                src: 'src/marketing/pages/products/index.js',
                priority: 1,
                children: [
                    {
                        url: '/products/ldi',
                        name: 'Learner Driver Insurance',
                        title: 'Flexible learner insurance for practice in a parent’s or friend’s car',
                        changefreq: 'monthly',
                        src: 'src/marketing/pages/products/ldi.js',
                        priority: 1,
                    },
                    {
                        url: '/products/nydi',
                        name: 'Named Young Driver Insurance',
                        title: 'Affordable alternative to being added as a named driver on a parent’s car',
                        changefreq: 'monthly',
                        src: 'src/marketing/pages/products/nydi.js',
                        priority: 1,
                    },
                    {
                        url: '/products/ndi',
                        name: 'Black Box Insurance',
                        title: 'Affordable black box insurance for young drivers aged 17- 24',
                        changefreq: 'monthly',
                        src: 'src/marketing/pages/products/ndi.js',
                        priority: 1,
                    },
                ],
            },
            {
                url: '/about-us',
                name: 'About Us',
                title: "We're the champions for young drivers and their parents",
                lastmod: '2018-02-12',
                src: 'src/marketing/pages/about-us.js',
                priority: 1,
            },
            {
                url: '/contact-us',
                name: 'Contact Us',
                changefreq: 'monthly',
                title: "We've here to help",
                src: 'src/marketing/pages/contact-us/index.js',
                priority: 1,
            },
            {
                url: '/register',
                name: 'Register',
                changefreq: 'monthly',
                title: "We're really looking forward to working with you",
                src: 'src/marketing/pages/register/index.js',
                priority: 1,
            },
        ],
    },
];

export const VEHICLE_CAVEATS = 'Covers cars up to 20 years of age, valued up to £30,000 and up to insurance group 32';
