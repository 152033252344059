import React, { Fragment } from 'react';
import Radio from '@marmalade/form/lib/components/radio';
import Input from '@marmalade/form/lib/components/input';

function Options() {
    return (
        <Fragment>
            <h2>Options</h2>
            <Radio
                label="Would you like to earn commission by promoting Marmalade with a banner on your website?"
                name="linkTo"
                options={['Yes', 'No']}
            />
            <Radio label="Are you a member of a larger group?" name="largerGroup" options={['Yes', 'No']} />
            <Input format="int" label="Enter your FCA number" name="fcaNumber" />
            <Input label="Website URL" name="url" placeholder="e.g. www.yoursite.com" autoComplete="url" />
        </Fragment>
    );
}

export default Options;
