import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import glamorous from 'glamorous';
import { H3 } from '@marmalade/bling/lib/headers/default';
import { LinkButton } from '@marmalade/button';
import urls from 'admin/urls';
import Text from '../../../../components/grey-paragraph';
import PanelWithIcon from '../panel-with-icon';

const TimeText = glamorous(Text)(({ theme }) => ({
    marginBottom: theme.space_sm,
}));

Notification.propTypes = {
    title: string.isRequired,
    text: string.isRequired,
    time: string.isRequired,
};

function Notification({ text, time, title }) {
    return (
        <PanelWithIcon icon="chat">
            <H3 css={{ marginBottom: 0 }}>{title}</H3>
            <TimeText>{time}</TimeText>
            <Text>{text}</Text>
            <LinkButton prominence="secondary" to={urls.notifications}>
                View all notifications
            </LinkButton>
        </PanelWithIcon>
    );
}

export default connect(state => ({
    title: 'Broker News',
    text: 'Missed our latest newsletter? Keep up to date with Marmalade news via the link below.',
    time: '10th May 2018',
}))(Notification);
