import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import Button, { ButtonGroup, LinkButton } from '@marmalade/button';
import { getPrices } from '@marmalade/ldi/lib/quote/selectors';
import domain from '@marmalade/domain';
import urls from '../../../urls';
import ResultsPanel from '../components/results/results-panel';
import MultiDayPrices, { priceProp } from '../components/results/multi-day-prices';
import { formId } from './form';
import Caveats from './caveats';

const QuoteWrapper = glamorous('div')(({ theme }) => ({
    marginBottom: theme.space_sm,
}));

class LDIResultsPanel extends Component {
    static propTypes = {
        prices: priceProp.isRequired,
    };

    render() {
        return (
            <ResultsPanel formId={formId}>
                <QuoteWrapper>
                    <MultiDayPrices prices={this.props.prices} />
                    <ButtonGroup>
                        <Button
                            Element="a"
                            href={`${domain('broker.provisionalmarmalade')}/broker-login`}
                            prominence="primary"
                            target="_blank"
                        >
                            Proceed to full quote
                        </Button>
                        <LinkButton to={urls.ldi}>Get a new quote</LinkButton>
                    </ButtonGroup>
                </QuoteWrapper>
                <Caveats />
            </ResultsPanel>
        );
    }
}

export default connect(state => ({
    prices: getPrices(state),
}))(LDIResultsPanel);
