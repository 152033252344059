import React from 'react';
import glamorous from 'glamorous';
import { node } from 'prop-types';

const outerPanelStyles = {
    margin: '0 0 3rem',
    borderRadius: '.3rem',
    padding: '2rem',
    position: 'relative',
    backgroundColor: 'white',
};

const OuterPanel = glamorous('div', { propsAreCssOverrides: true })(outerPanelStyles);

const panelShadowStyles = () => ({
    position: 'absolute',
    top: '100%',
    left: '2%',
    right: '2%',
    height: '.5rem',
    borderRadius: '0 0 .3rem .3rem',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
});

const PanelShadow = glamorous('div')(panelShadowStyles);

LoginPanel.propTypes = {
    children: node.isRequired,
};

function LoginPanel({ children, ...rest }) {
    return (
        <OuterPanel {...rest}>
            {children}
            <PanelShadow />
        </OuterPanel>
    );
}

export default LoginPanel;
