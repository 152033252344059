import React from 'react';
import { Helmet } from 'react-helmet';
import pathInformation from './path-information';
import withSitemap from './hocs/with-sitemap';
import { withRouter } from 'react-router-dom';
import { func, oneOfType, string } from 'prop-types';
import { sitemap } from './prop-types';
import { location } from '@marmalade/prop-types';

Head.propTypes = {
    title: oneOfType([func, string]),
    description: oneOfType([func, string]),
    sitemap,
    location,
};

// This component injects a title and meta tags into the head of the document, uses information from the sitemap and alllows you to format the title how you like
function Head({
    title = ({ title }) => `${title} | Marmalade Broker`,
    description = ({ description }) => description,
    sitemap,
    location,
}) {
    const path = pathInformation(location.pathname, sitemap);
    return (
        <Helmet>
            <title>{typeof title === 'function' ? title(path) : title}</title>
            <meta charSet="utf-8" />
            {path.description && (
                <meta name="description" content={typeof description === 'function' ? description(path) : title} />
            )}
        </Helmet>
    );
}

export default withSitemap(withRouter(Head));
