import React from 'react';
import glamorous, { withTheme, Div, A } from 'glamorous';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { Container } from '@marmalade/grid';
import Icon from '@marmalade/icon';
import Collapse from 'react-collapse';
import { object, arrayOf, shape, string, func, bool } from 'prop-types';

const linkStyles = ({ theme }) => ({
    color: theme.colorWhite,
    paddingRight: '2rem',
    fontSize: '1.6rem',
    '&:hover': {
        color: theme.colorWhite,
    },
});

const Link = glamorous(NavLink)(linkStyles);

const menuLinkStyles = ({ theme }) => ({
    color: theme.colorWhite,
    textDecoration: 'none',
});

const MenuLink = glamorous(RouterLink)(menuLinkStyles);

const menuItemStyles = ({ theme, primary = false }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #444',
    alignItems: 'center',
    backgroundColor: primary ? theme.colorPrimary : undefined,
    breakInside: 'avoid',
    padding: '0 1.8rem',
    cursor: 'pointer',
    height: '5rem',
    fontWeight: 700,
    fontSize: '1.6rem',
    transition: '0.15s ease-in-out',
    '&:hover': {
        color: theme.colorWhite,
        backgroundColor: primary ? theme.colorPrimary : '#363636',
    },
});

const MenuItem = glamorous('div', { propsAreCssOverrides: true })(menuItemStyles);

const wrapperStyles = ({ theme, columnCount, tablet }) => ({
    backgroundColor: theme.colorBlack,
    height: tablet ? undefined : '5.4rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.colorWhite,
});

const Wrapper = glamorous.div(wrapperStyles, ({ theme, tablet }) =>
    tablet
        ? {
              '& a': {
                  ':hover': {
                      color: theme.colorWhite,
                      textDecoration: 'none',
                  },
              },
          }
        : {}
);

B2BMenu.propTypes = {
    theme: object,
    options: arrayOf(shape({ to: string, text: string, onClick: func, primary: bool })),
    open: bool,
    tablet: bool,
    mobile: bool,
    onLogin: func,
};

function B2BMenu({ theme, options, open, tablet, mobile, onLogin }) {
    if (tablet) {
        return (
            <Collapse isOpened={open}>
                <Wrapper tablet={tablet}>
                    <Div columnGap="0" columnCount={mobile ? 1 : 2} width="100%">
                        {options
                            .concat({
                                text: (
                                    <span>
                                        <Icon type="cordless" fontSize="2rem" /> {theme.telephone}
                                    </span>
                                ),
                                href: `tel:${theme.telephone}`,
                            })
                            .concat(
                                onLogin
                                    ? [
                                          {
                                              text: 'Login',
                                              onClick: onLogin,
                                              primary: true,
                                          },
                                      ]
                                    : []
                            )
                            .map(({ to, text, primary = false, onClick, href }, i) => {
                                if (onClick) {
                                    return (
                                        <MenuItem onClick={onClick} primary={primary} key={`${i}`}>
                                            {text}
                                            <Icon type="arrow-right" />
                                        </MenuItem>
                                    );
                                }
                                if (href) {
                                    return (
                                        <A href={href} color={theme.colorWhite} textDecoration="none" key={`${i}`}>
                                            <MenuItem primary={primary}>
                                                {text}
                                                <Icon type="arrow-right" color={theme.colorWhite} />
                                            </MenuItem>
                                        </A>
                                    );
                                }
                                return (
                                    <MenuLink to={to} key={`${i}`}>
                                        <MenuItem primary={primary}>
                                            {text}
                                            <Icon type="arrow-right" color={theme.colorWhite} />
                                        </MenuItem>
                                    </MenuLink>
                                );
                            })}
                    </Div>
                </Wrapper>
            </Collapse>
        );
    }
    return (
        <Wrapper>
            <Container>
                {options.map((option, i) => (
                    <Link to={option.to} key={`${i}`}>
                        {option.text}
                    </Link>
                ))}
            </Container>
        </Wrapper>
    );
}

const ThemedB2BMenu = withTheme(B2BMenu);

export default ({ options, tablet, mobile, onLogin }) => (open, toggle) => (
    <ThemedB2BMenu options={options} open={open} tablet={tablet} mobile={mobile} onLogin={onLogin} />
);
