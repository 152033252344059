import { Panel } from '@marmalade/panel';
import { node, string } from 'prop-types';
import React from 'react';
import CarDescription from './car-description';
import Title from './title';

ResultsPanel.propTypes = {
    children: node.isRequired,
    formId: string.isRequired,
    title: string,
};

function ResultsPanel({ children, formId, title }) {
    return (
        <Panel noBorder>
            <Title title={title} />
            <CarDescription formId={formId} />
            {children}
        </Panel>
    );
}

export default ResultsPanel;
