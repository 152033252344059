import glamorous from 'glamorous';
import { between } from '@marmalade/themes';

const styles = ({ mobileStyles = {}, tabletStyles = {}, baseStyles = {} }) => ({ theme }) => ({
    ...baseStyles,
    [between(theme.breakpoint_sm, theme.breakpoint_lg)]: tabletStyles,
    [between(0, theme.breakpoint_sm)]: mobileStyles,
});

export const H1 = glamorous('h1', { propsAreCssOverrides: true })(
    styles({
        baseStyles: {
            fontWeight: 900,
            fontSize: '46px',
            lineHeight: '48px',
        },
        tabletStyles: {
            lineHeight: '50px',
        },
        mobileStyles: {
            fontSize: '30px',
            lineHeight: '36px',
        },
    })
);

export const H2 = glamorous('h2', { propsAreCssOverrides: true })(
    styles({
        baseStyles: {
            fontWeight: 700,
            fontSize: '30px',
            lineHeight: '24px',
        },
        mobileStyles: {
            fontSize: '26px',
        },
    })
);

export const H3 = glamorous('h3', { propsAreCssOverrides: true })(
    styles({
        baseStyles: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '24px',
        },
        mobileStyles: {
            fontSize: '22px',
        },
    })
);
