import React from 'react';
import VehicleLookup from '@marmalade/form/lib/components/vehicle-lookup';

function BrokerVehicleLookup({ ...rest }) {
    return (
        <VehicleLookup
            customLabels={{
                regLookup: 'What is the car registration?',
                regPlaceholder: 'Enter Reg',
                details: 'Car details',
                chooseManual: "Don't know the registration?",
                resetDetails: 'Change car?',
                resetManual: 'Know the reg?',
            }}
            {...rest}
        />
    );
}

export default BrokerVehicleLookup;
