import React from 'react';
import glamorous, { Div } from 'glamorous';
import Mask from './mask';
import { string, node, number } from 'prop-types';

const backgroundContainerStyles = ({
    angle,
    width,
    background,
    backgroundSize = 'cover',
    backgroundPosition,
    overflow,
    paddingRight,
}) => ({
    background,
    backgroundSize,
    width: '100%',
    marginBottom: `-${overflow}`,
    backgroundPosition,
    paddingRight,
});

const BackgroundContainer = glamorous('div')(backgroundContainerStyles);

Hero.propTypes = {
    background: string,
    children: node,
    angle: number,
    color: string,
    overflow: string,
    backgroundSize: string,
    rightOverflow: number,
};

function Hero({ background, backgroundSize, children, angle, color, overflow = false }) {
    return (
        <BackgroundContainer background={background} backgroundSize={backgroundSize} overflow={overflow}>
            <Div display="flex" flexDirection="column">
                {children}
            </Div>
            {overflow ? (
                <Div height={0} position="relative">
                    <Div
                        paddingBottom={`${Math.tan((angle * Math.PI) / 180) * 100}%`}
                        position="absolute"
                        left={0}
                        right={0}
                        bottom={overflow && 0}
                    >
                        <Mask color={color} />
                    </Div>
                </Div>
            ) : (
                <Div
                    paddingBottom={`${Math.tan((angle * Math.PI) / 180) * 100}%`}
                    position="relative"
                    bottom={overflow && 0}
                >
                    <Mask color={color} />
                </Div>
            )}
        </BackgroundContainer>
    );
}

export default Hero;
